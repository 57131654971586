
  import { defineComponent, PropType } from 'vue';
  import { RawDataExportOrderProgress } from '@/api/station';

  export default defineComponent({
    name: 'RawDataExportProgress',
    components: {},
    props: {
      progress: { type: String as PropType<RawDataExportOrderProgress>, required: true },
    },
    computed: {
      info() {
        switch (this.progress) {
          case RawDataExportOrderProgress.Pending:
            return { color: 'info', text: this.$t('RawDataExport.Progress.Pending') };
          case RawDataExportOrderProgress.Processing:
            return { color: 'info', text: this.$t('RawDataExport.Progress.Processing') };
          case RawDataExportOrderProgress.Completed:
            return { color: 'success', text: this.$t('RawDataExport.Progress.Completed') };
          case RawDataExportOrderProgress.Failed:
            return { color: 'error', text: this.$t('RawDataExport.Progress.Failed') };
          default:
            return { color: 'default', text: this.$t('RawDataExport.Progress.Unknown') };
        }
      },
    },
  });
