
  import { defineComponent, PropType } from 'vue';
  import { endOfDay, startOfDay } from 'date-fns';
  import Datepicker from '@vuepic/vue-datepicker';
  import type { VForm } from 'vuetify/components';
  import {
    calculateUtcForStationTimeFromPartialISO,
    calculateUtcForStationTimeFromString,
    formatToTimezone,
    calculateUtcFromDateOrFullISO,
  } from '@/common/utils/time';
  import LabeledDivider from '@/common/components/LabeledDivider/LabeledDivider.vue';
  import '@vuepic/vue-datepicker/dist/main.css';

  const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

  export interface DateRangeChange {
    from: Date;
    to: Date;
    timeZone: string;
  }

  // TODO: Replace with vuetify@3.6 DateInput component, see geoviz#690 and geoviz#691
  export default defineComponent({
    name: 'DateRangeSelector',
    components: { LabeledDivider, Datepicker },
    emits: ['formValidChanged'],
    props: {
      timeZone: { type: String, required: true },
      from: Date,
      to: Date,
      locale: { type: String, required: true },
      onChange: Function as PropType<({ from, to, timeZone }: DateRangeChange) => Promise<void> | void>,
    },
    data() {
      const currentUTCDate = new Date(new Date().toUTCString());
      const fromDate = this.from ?? calculateUtcFromDateOrFullISO(currentUTCDate, this.timeZone, startOfDay);
      const toDate = this.to ?? calculateUtcFromDateOrFullISO(currentUTCDate, this.timeZone, endOfDay);

      return {
        isFormValid: null,
        fromDate,
        toDate,
        pickerDates: [
          formatToTimezone(fromDate, this.timeZone, DATE_PICKER_FORMAT),
          formatToTimezone(toDate, this.timeZone, DATE_PICKER_FORMAT),
        ],
      };
    },
    watch: {
      fromAndToDate: {
        async handler() {
          const { valid } = await (this.$refs.form as InstanceType<typeof VForm>).validate();
          if (valid && this.onChange) {
            await this.onChange({
              from: this.fromDate,
              to: this.toDate,
              timeZone: this.timeZone,
            });
          }
        },
        flush: 'post', // ensure watcher runs after $refs.form was updated and reflects the new state
      },
      isFormValid(valid: boolean) {
        this.$emit('formValidChanged', valid);
      },
    },
    computed: {
      selectedFromDateDisplayFormat: {
        get() {
          return formatToTimezone(this.fromDate, this.timeZone, 'P');
        },
        set(value: string) {
          const newFromDate = calculateUtcForStationTimeFromString(value, this.timeZone, this.locale, startOfDay);

          if (newFromDate) {
            this.fromDate = newFromDate;
            this.pickerDates = [
              formatToTimezone(newFromDate, this.timeZone, DATE_PICKER_FORMAT),
              formatToTimezone(this.toDate, this.timeZone, DATE_PICKER_FORMAT),
            ];
          }
        },
      },
      selectedToDateDisplayFormat: {
        get() {
          return formatToTimezone(this.toDate, this.timeZone, 'P');
        },
        set(value: string) {
          const newToDate = calculateUtcForStationTimeFromString(value, this.timeZone, this.locale, endOfDay);

          if (newToDate) {
            this.toDate = newToDate;
            this.pickerDates = [
              formatToTimezone(this.fromDate, this.timeZone, DATE_PICKER_FORMAT),
              formatToTimezone(newToDate, this.timeZone, DATE_PICKER_FORMAT),
            ];
          }
        },
      },
      fromDateRules() {
        const isNotEmpty = (value: string) => value !== '';
        const isValidDate = (value: string): boolean => {
          return !!calculateUtcForStationTimeFromString(value, this.timeZone, this.locale);
        };
        const isBeforeTo = (value: string): boolean => {
          const parsedDate = calculateUtcForStationTimeFromString(value, this.timeZone, this.locale, startOfDay);

          if (parsedDate) {
            return this.toDate.valueOf() >= parsedDate.valueOf();
          }

          return false;
        };
        const isAfter1990 = (value: string): boolean => {
          const parsedDate = calculateUtcForStationTimeFromString(value, this.timeZone, this.locale);

          if (parsedDate) {
            return parsedDate.getUTCFullYear() > 1989;
          }

          return false;
        };

        return [
          (value: string) => isNotEmpty(value) || String(this.$t('DateRangeSelector.Controls.DateFilterRequiredText')),
          (value: string) =>
            isValidDate(value) || String(this.$t('DateRangeSelector.Controls.DateFilterInvalidDateText')),
          (value: string) =>
            isBeforeTo(value) || String(this.$t('DateRangeSelector.Controls.DateFilterMustComeBeforeToDateText')),
          (value: string) =>
            isAfter1990(value) || String(this.$t('DateRangeSelector.Controls.DateFilterMustBeAfter1990Text')),
        ];
      },
      toDateRules() {
        const isNotEmpty = (value: string) => value !== '';
        const isValidDate = (value: string): boolean =>
          !!calculateUtcForStationTimeFromString(value, this.timeZone, this.locale);
        const isAfterFrom = (value: string): boolean => {
          const parsedDate = calculateUtcForStationTimeFromString(value, this.timeZone, this.locale, endOfDay);

          if (parsedDate) {
            return this.fromDate.valueOf() <= parsedDate.valueOf();
          }

          return false;
        };

        return [
          (value: string) => isNotEmpty(value) || String(this.$t('DateRangeSelector.Controls.DateFilterRequiredText')),
          (value: string) =>
            isValidDate(value) || String(this.$t('DateRangeSelector.Controls.DateFilterInvalidDateText')),
          (value: string) =>
            isAfterFrom(value) || String(this.$t('DateRangeSelector.Controls.DateFilterMustComeAfterFromDateText')),
        ];
      },
      fromAndToDate() {
        // evaluated when fromDate OR toDate changes
        return `${this.fromDate.valueOf()}${this.toDate.valueOf()}`;
      },
      pickerFormat() {
        return DATE_PICKER_FORMAT;
      },
    },
    methods: {
      async handlePickerChange(value: DateTuple) {
        // 'value' is guaranteed to be a ISO 8601 format: yyyy-MM-dd
        let fromDate: Date, toDate: Date;

        if (!value || (!value[0] && !value[1])) {
          return;
        }

        // handle a case where the user selects backwards (later date first, then earlier date).
        // in that case [0] is toDate and [1] is fromDate
        if (value[0] > value[1]) {
          fromDate = calculateUtcForStationTimeFromPartialISO(value[1], this.timeZone, startOfDay);
          toDate = calculateUtcForStationTimeFromPartialISO(value[0], this.timeZone, endOfDay);
        } else {
          fromDate = calculateUtcForStationTimeFromPartialISO(value[0], this.timeZone, startOfDay);
          toDate = calculateUtcForStationTimeFromPartialISO(value[1], this.timeZone, endOfDay);
        }

        this.fromDate = fromDate;
        this.toDate = toDate;
      },
    },
  });

  export type DateTuple = [string, string];
