
  import { defineComponent } from 'vue';
  import { mdiCloseThick } from '@mdi/js';

  export default defineComponent({
    name: 'ReturnToMobileApp',
    props: {
      returnUrl: { type: String },
    },
    computed: {
      mdiCloseIcon() {
        return mdiCloseThick;
      },
      isValidReturnUrl(): boolean {
        if (!this.returnUrl) {
          return false;
        }

        const url = new URL(this.returnUrl);
        const allowedDomains = ['m.geopraevent.ch', 'm.geoprevent.com'];

        // basic sanity check
        return allowedDomains.includes(url.host);
      },
    },
    methods: {
      goBack() {
        if (!this.returnUrl) {
          return;
        }

        window.location.href = this.returnUrl;
      },
    },
  });
