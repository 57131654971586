import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-066b3022"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-center spinner-container" }
const _hoisted_2 = { class: "text-h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Announcement = _resolveComponent("Announcement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_Spinner, {
      class: "spinner text-secondary",
      size: "96"
    }, null, 512), [
      [_vShow, !_ctx.isExpired]
    ]),
    _withDirectives(_createVNode(_component_Announcement, null, {
      title: _withCtx(() => [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('Error.LoginExpiredTitle')), 1)
      ]),
      illustration: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "x-large",
          icon: _ctx.expiredIcon
        }, null, 8, ["icon"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_btn, {
          to: "/",
          variant: "text",
          color: "primary",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Error.BackToHomeLink')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.isExpired]
    ])
  ]))
}