import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32450bf7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorTemplate = _resolveComponent("ErrorTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.err)
      ? _renderSlot(_ctx.$slots, "error", {
          key: 0,
          err: _ctx.err,
          vm: _ctx.vm,
          info: _ctx.info
        }, () => [
          _createVNode(_component_ErrorTemplate, { error: _ctx.err }, null, 8, ["error"])
        ], true)
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
  ]))
}