import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9332e574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-body-2 text-primary" }
const _hoisted_2 = { class: "text-body-2 text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_RawDataExportProgress = _resolveComponent("RawDataExportProgress")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, {
    class: "order-list-item my-1",
    rounded: ""
  }, {
    title: _withCtx(() => [
      _createVNode(_component_v_container, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "ma-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_label, {
                    class: "d-block",
                    title: _ctx.timeStampTitle
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.timeStampTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "ma-1" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, [
                    _createVNode(_component_v_badge, {
                      content: _ctx.order.measurements.length,
                      color: "primary",
                      inline: ""
                    }, null, 8, ["content"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('RawDataExport.Measurements')), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "ma-1" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.order.exportFrom)) + " – " + _toDisplayString(_ctx.formatDate(_ctx.order.exportTo)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    append: _withCtx(() => [
      _createVNode(_component_RawDataExportProgress, {
        progress: _ctx.order.progress
      }, null, 8, ["progress"]),
      _createVNode(_component_v_btn, {
        style: _normalizeStyle(_ctx.isComplete(_ctx.order.progress) && _ctx.order.downloadUrl ? 'visibility: visible;' : 'visibility: hidden;'),
        color: "grey",
        title: _ctx.$t('App.Download'),
        icon: _ctx.mdiDownload,
        variant: "text",
        href: _ctx.order.downloadUrl
      }, null, 8, ["style", "title", "icon", "href"])
    ]),
    _: 1
  }))
}