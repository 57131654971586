
  import { defineComponent } from 'vue';
  import { GlobalEvents } from 'vue-global-events';
  import { mdiFilter } from '@mdi/js';
  import { mapState, mapActions } from 'pinia';

  import { StationStatus } from '@/api/station/types';
  import StationListItem from '@/pages/Navigation/components/StationListItem/StationListItem.vue';
  import AutoUpdate from '@/common/components/AutoUpdate';
  import { excludeFormElements } from '@/common/utils/globalEventFilters';
  import KeyboardShortcut from '@/common/components/KeyboardShortcut/KeyboardShortcut.vue';

  import type { IStation } from '@/areas/Station/store/types';
  import { useStationStore } from '@/areas/Station/store';

  export const maxVisibleStations = 30;
  export const showSearchThreshold = 10;
  export const StationsNavigationComponentName = 'StationsNavigation';
  export const showInactiveStorageKey = 'gp.showInactiveStations';

  export default defineComponent({
    name: StationsNavigationComponentName,
    components: { KeyboardShortcut, AutoUpdate, StationListItem, GlobalEvents },
    props: {
      isOrphan: Boolean,
      enableStationFavourites: Boolean,
    },
    emits: ['forceShowStations'],
    data: () => ({
      showInactive: Boolean(window.localStorage.getItem(showInactiveStorageKey)) || false,
      search: '',
      showAll: false,
      hasSearchFocus: false,
    }),
    watch: {
      showInactive(value: boolean) {
        if (value) {
          window.localStorage.setItem(showInactiveStorageKey, '1');
        } else {
          window.localStorage.removeItem(showInactiveStorageKey);
        }
      },
    },
    computed: {
      mdiFilterIcon() {
        return mdiFilter;
      },
      favorites() {
        return this.stationsList.filter(this.favouriteFilter).filter(this.inactiveFilter).filter(this.searchFilter);
      },
      nonFavorites() {
        const filteredList = this.stationsList
          .filter((item) => !this.favouriteIds.includes(item.id))
          .filter(this.inactiveFilter)
          .filter(this.searchFilter);

        if (this.showAll || this.search) {
          return filteredList;
        } else {
          return filteredList.slice(0, maxVisibleStations);
        }
      },
      inactiveFilter() {
        return (item: IStation) => {
          if (this.showInactive) {
            return true;
          }
          return this.favouriteIds.includes(item.id) || item.properties.modeOfOperation !== StationStatus.Inactive;
        };
      },
      searchFilter() {
        return (item: IStation) => item.properties.name.toLowerCase().indexOf(this.search?.toLowerCase() || '') > -1;
      },
      favouriteFilter() {
        return (item: IStation) => this.favouriteIds.includes(item.id);
      },
      showShowAllButton() {
        return this.stationsList.length > maxVisibleStations && !this.showAll && !this.search ? true : false;
      },
      showSearch() {
        return this.stationsList.length > showSearchThreshold;
      },
      hasInactiveStations() {
        return (
          this.stationsList.filter((item) => item.properties.modeOfOperation === StationStatus.Inactive).length > 0
        );
      },
      ...(mapState(useStationStore, ['stationsList', 'favouriteIds']) as {
        stationsList(): IStation[];
        favouriteIds(): string[];
      }),
    },
    methods: {
      resetForceStationsNavigation(event: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent;

        if (!isKeyboardEvent) {
          this.$emit('forceShowStations', false);
        }
      },
      onKeyboardEvent() {
        const vNode = this.$refs.stationsSearch as HTMLInputElement;

        if (vNode) {
          vNode.focus();
        }
      },
      onEscEvent() {
        const vNode = this.$refs.stationsSearch as HTMLInputElement;

        if (vNode) {
          vNode.blur();
          this.search = '';
        }
      },
      excludeFilter(e: KeyboardEvent) {
        return excludeFormElements(e);
      },
      ...mapActions(useStationStore, ['checkForNewMessages']),
    },
  });
