import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, CanceledError, AxiosRequestHeaders } from 'axios';
import { HttpErrorKind } from '@/api/utils/HttpErrorEnums';
import HttpError from '@/api/utils/HttpError';
import * as Sentry from '@sentry/browser';

import { useAuthStore } from '@/pages/Oidc/store';

export default class BaseApiClient {
  protected client: AxiosInstance;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
      },
      // Required so that cookies (e.g. for Ingress session stickiness) are sent with cross-domain XHR requests (see
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials for details)
      withCredentials: true,
    });

    this.client.interceptors.request.use(this.authTokenInterceptor.bind(this));
    this.client.interceptors.response.use(undefined, this.errorInterceptor.bind(this));
  }

  get get() {
    return this.client.get;
  }
  get post() {
    return this.client.post;
  }
  get put() {
    return this.client.put;
  }
  get patch() {
    return this.client.patch;
  }
  get delete() {
    return this.client.delete;
  }
  get head() {
    return this.client.head;
  }

  private authTokenInterceptor(config: AxiosRequestConfig) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    config.headers['Authorization'] = config.headers['Authorization'] ?? `Bearer ${this.getAuthToken()}`;

    return config as Omit<AxiosRequestConfig, 'headers'> & { headers: AxiosRequestHeaders };
  }

  private maskJwt(jwt?: string): string | undefined {
    if (!jwt) {
      return;
    }

    const maskMatchFunction = (match: string): string => match.replace(/./gi, '*');
    const jwtSignatureRegex = /(?:\.[^.]+)$/i;

    return jwt.replace(jwtSignatureRegex, maskMatchFunction);
  }

  private errorInterceptor(error: AxiosError<any>): Promise<HttpError | AxiosError> {
    let code: HttpErrorKind, message: string;

    // https://axios-http.com/docs/handling_errors
    if (error.response) {
      // Server responded
      code = error.response.status;
      message = error.response.data?.message || error.response.statusText || `${code}`;
    } else if (error instanceof CanceledError) {
      // Request has been cancelled by AbortController, propagate immediately
      return Promise.reject(error);
    } else if (error.request) {
      // Request timed out
      code = HttpErrorKind.ConnectionTimeout;
      message = error.message || 'Request Timeout';
    } else {
      // Setting up request failed
      code = HttpErrorKind.InternalServerError;
      message = error.message || 'Failed to prepare request';
    }

    const httpError = new HttpError(code, message, error.config);
    const { request } = httpError;

    const scope = Sentry.getCurrentScope();
    let maskedJwt;

    if (request?.headers && request?.headers['Authorization']) {
      maskedJwt = this.maskJwt(request?.headers['Authorization'] as string);
    }

    scope.setContext('axios_request', request);
    scope.setContext('axios_response', {
      code,
      message,
      axiosCode: error.code,
      axiosMessage: error.message,
    });

    scope.setContext('jwt', maskedJwt ? { maskedJwt } : null);

    scope.setContext('other', {
      onlineStatus: navigator.onLine,
    });

    return Promise.reject(httpError);
  }

  private getAuthToken(): string {
    const authStore = useAuthStore();
    return authStore.accessToken;
  }
}
