import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f7619e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalEvents = _resolveComponent("GlobalEvents")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_KeyboardShortcut = _resolveComponent("KeyboardShortcut")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_PanelListItem = _resolveComponent("PanelListItem")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_ViewListItem = _resolveComponent("ViewListItem")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_ctx.activeStation)
    ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_GlobalEvents, {
            onKeydown: _withKeys(_withModifiers(_ctx.emitForceShowStations, ["exact","prevent"]), ["b"]),
            filter: _ctx.excludeFilter
          }, null, 8, ["onKeydown", "filter"]),
          (!_ctx.isOrphan)
            ? (_openBlock(), _createBlock(_component_v_list_item, {
                key: 0,
                onClick: _withModifiers(_ctx.emitForceShowStations, ["stop"]),
                "data-testid": "backLink",
                density: "compact"
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.mdiChevronLeftIcon), 1)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { class: "text-caption" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('App.BackToStationsLinkText')) + " ", 1),
                        (_ctx.$vuetify.display.mdAndUp)
                          ? (_openBlock(), _createBlock(_component_KeyboardShortcut, {
                              key: 0,
                              class: "ml-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("b")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_list_item, {
            density: "compact",
            class: "viewList_main mb-2 text-body-2 bg-grey-lighten-3"
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_v_tooltip, { location: "bottom" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_icon, _mergeProps({ color: "grey-darken-2 text-high-emphasis" }, props), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.mdiStationIcon), 1)
                    ]),
                    _: 2
                  }, 1040),
                  (_ctx.deEmphasizedStationIcon)
                    ? (_openBlock(), _createBlock(_component_v_badge, _mergeProps({
                        key: 0,
                        "offset-y": "5",
                        "offset-x": "-3",
                        bordered: "",
                        color: "grey-lighten-1",
                        class: "viewList_badge"
                      }, props), {
                        badge: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            color: "white",
                            icon: _ctx.deEmphasizedStationIcon
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1040))
                    : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('App.ModeOfOperationLabel', { modeOfOperation: _ctx.activeStation.properties.modeOfOperation })), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, { class: "item--break text-body-2" }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.activeStation.properties.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (_ctx.enableStationFavourites)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      onClick: _withModifiers(_ctx.toggleFavourite, ["prevent","stop"]),
                      color: _ctx.isFavourite ? 'yellow-darken-2' : undefined,
                      icon: "",
                      variant: "text",
                      "data-testid": "toggleFavouriteBtn",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isFavourite)
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 0,
                              size: "large"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.mdiStarIcon), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_v_icon, {
                              key: 1,
                              size: "large",
                              color: "grey-darken-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.mdiStarOutlineIcon), 1)
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    }, 8, ["onClick", "color"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1024),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panels, (item) => {
            return (_openBlock(), _createBlock(_component_PanelListItem, {
              key: item.name,
              "is-visible": item.isVisible,
              name: item.name,
              target: item.href ? '_blank' : undefined,
              href: item.href,
              to: item.to,
              icon: item.icon,
              "has-unread-messages": item.hasUnreadMessages,
              onClick: _ctx.emitNavigate
            }, null, 8, ["is-visible", "name", "target", "href", "to", "icon", "has-unread-messages", "onClick"]))
          }), 128)),
          _createVNode(_component_v_list_subheader, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('App.OtherViewsLabel')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewList, (item) => {
            return (_openBlock(), _createBlock(_component_ViewListItem, {
              key: item.id,
              kind: item.kind,
              "view-id": item.id,
              "station-id": _ctx.activeStationId,
              title: item.title,
              onClick: _ctx.emitNavigate,
              "required-user-level": item.requiredUserLevel
            }, null, 8, ["kind", "view-id", "station-id", "title", "onClick", "required-user-level"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}