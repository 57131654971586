export default class UserFriendlyError extends Error {
  constructor(
    public userFriendlyMessage: string,
    public originalMessage?: string,
  ) {
    super(originalMessage || userFriendlyMessage);

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, UserFriendlyError.prototype);
  }
}
