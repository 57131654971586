import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58e3e707"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "measurement-list mx-4" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "text-body-2 text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, { density: "compact" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            "prepend-inner-icon": _ctx.mdiMagnify,
            label: String(_ctx.$t('RawDataExport.Actions.SearchMeasurement')),
            "hide-details": "",
            modelValue: _ctx.filterInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterInput) = $event))
          }, null, 8, ["prepend-inner-icon", "label", "modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredMeasurements, (item) => {
          return (_openBlock(), _createBlock(_component_v_list_item, {
            key: item.id,
            density: "compact"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox_btn, {
                id: item.id,
                value: item.id,
                modelValue: _ctx.selectedIds,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedIds) = $event)),
                label: item.name,
                color: "primary"
              }, null, 8, ["id", "value", "modelValue", "label"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _createVNode(_component_v_list_item, {
        density: "compact",
        class: "mx-4 border-t-sm"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_checkbox_btn, {
              color: "primary",
              label: String(_ctx.$t('RawDataExport.Actions.SelectAll')),
              modelValue: _ctx.allSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.allSelected) = $event)),
              onclick: _ctx.toggleSelectAll
            }, null, 8, ["label", "modelValue", "onclick"]),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('RawDataExport.Actions.Selected', { numberMeasurements: _ctx.selectedIds.length })), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}