import * as Sentry from '@sentry/browser';
import { type User } from 'oidc-client-ts';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { oidcSettingsFactory } from '@/pages/Oidc/utils/oidc-settings-factory';
import { useRootStore } from '@/store';
import type { WithGeopraeventProfile } from '@/pages/Oidc/store/types';
import { AuthService } from '@/pages/Oidc/utils/AuthService';

export const useAuthStore = defineStore('auth', () => {
  const authService = new AuthService(
    oidcSettingsFactory({
      authority: runtimeAppSettings.oidcAuthority,
      rootUrl: runtimeAppSettings.rootUrl,
      redirectPath: runtimeAppSettings.oidcRedirectPath,
      clientId: runtimeAppSettings.oidcClientId,
      postLogoutRedirectUrl: runtimeAppSettings.postLogoutRedirectUrl,
    }),
  );

  const user = ref<User | null>(null);
  const accessToken = computed(() => user.value?.access_token || 'no or invalid access token');
  const isAuthenticated = computed(() => !!(user.value && !user.value?.expired));

  // subscribe to identity provider federated logouts
  authService.on('userSignedOut', async () => {
    // remove user from vuex store and localstorage when federated logout occurred.
    // disable for localhost to avoid being logged-out immediately after login,
    // as on localhost cookies will never be same domain with ID Provider,
    // so most browsers (also Chrome Incognito by default) will block those and trigger a signOut.
    if (runtimeAppSettings.rootUrl.indexOf('localhost') < 0) {
      Sentry.withScope(function (scope) {
        scope.setLevel('info');
        Sentry.captureException(new Error('User was logged out, refreshing window next'));
      });

      await Sentry.flush(1000);
      await silentLogout();

      window.location.reload();
    }
  });

  authService.on('userLoaded', async () => {
    const rootStore = useRootStore();
    const oidcUser = await authService.getUser();

    if (!oidcUser) {
      return;
    }

    user.value = oidcUser;

    const profile: WithGeopraeventProfile = oidcUser.profile;
    const { geopraevent: gpClaims, impersonator } = profile;
    const { language, status: userLevel, customization, preferredTimezone } = gpClaims || {};
    const impersonatorUsername = impersonator?.username;

    if (language) {
      // set UI language
      rootStore.updateLocale(language);
    }

    if (userLevel) {
      rootStore.updateUserLevel(userLevel);
    }

    if (impersonatorUsername) {
      rootStore.updateImpersonater(impersonatorUsername);
    }

    if (customization) {
      rootStore.updateCustomization(customization);
    }

    if (profile && profile.name && profile.preferred_username) {
      rootStore.updateUser({
        fullName: profile.name,
        nick: profile.preferred_username.trim(),
      });
    }

    if (preferredTimezone) {
      rootStore.updatePreferredTimezone(preferredTimezone);
    }

    // set user for Sentry error logging
    const scope = Sentry.getCurrentScope();
    scope.setUser({ id: profile.sub });

    return oidcUser;
  });

  async function login(redirectPath?: string) {
    await authService.login(redirectPath);
  }

  async function logout() {
    await authService.logout();
    user.value = null;
  }

  async function silentLogout() {
    await authService.removeUser();
    user.value = null;
  }

  async function handleLoginCallback() {
    return await authService.handleLoginCallback();
  }

  return {
    user,
    accessToken,
    login,
    logout,
    handleLoginCallback,
    isAuthenticated,
    silentLogout,
  };
});
