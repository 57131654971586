import { h, type SetupContext } from 'vue';
import { useIntl } from 'vue-intl';

export interface IFormattedMessageProps {
  id: string;
  tag?: string;
  values?: Record<string, any>;
}

/**
 * Component for vue-intl that allows to insert rich text messages.
 * Note that content insertion into the provided tag requires vue's built-in dynamic <Component>
 *
 * @example
 * <FormattedMessage id="myKey">
 *   <template #a="Content">
 *     <a href="#"><Component :is="Content" /></a>
 *   </template>
 * </FormattedMessage>
 *
 * // en.json
 * { "myKey": "Please accept <a>terms and conditions</a> before continuing" }
 */
export const FormattedMessage = (props: IFormattedMessageProps, context: Omit<SetupContext<any>, 'expose'>) => {
  const { id, tag, values = {}, ...rest } = props;
  const intl = useIntl();
  const slotNames = Object.keys(context.slots);
  const message = intl.formatMessage(
    { id },
    {
      ...values,
      ...slotNames.reduce(
        (slots, name) => {
          slots[name] = (content) => context.slots[name]!(() => content);
          return slots;
        },
        {} as Record<string, (content: string[]) => any>,
      ),
    },
  );

  return tag ? h(tag, rest, message) : message;
};
