import { WebsocketClient } from '@/api/utils/WebsocketClient';
import { IStationStateSubscriptionParameter } from '@/api/observations/types';

export const getStationObserver = ({ stationId }: IStationStateSubscriptionParameter): WebsocketClient => {
  // At some point we might re-use existing connections (re-start them) when browsing between stations.
  // For now we just initiate a new one every time this function is called
  const client = WebsocketClient.Create(
    `${runtimeAppSettings.observationsApiBaseUrl}/_changes/ws?station=${stationId}`,
  );

  // terminate connection automatically when user browses away (non-client-side navigation)
  window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
    if (client) {
      client.destroy();
    }

    delete e['returnValue']; // Ensure browser unload is not blocked (see https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload)
  });

  return client;
};
