<template>
  <v-row justify="center" align-content="center" class="wrapper">
    <v-col class="announcement" :style="{ 'max-width': width }">
      <v-row>
        <v-col class="text-center d-flex justify-center align-center">
          <div class="illustration-wrapper text-grey-darken-2">
            <slot name="illustration" class="illustration"></slot>
          </div>
          <div class="text-grey-darken-2">
            <slot name="title"></slot>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center text-grey-darken-1">
          <slot name="content">
            {{ $t('Home.WelcomeMessage') }}
          </slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { defineComponent } from 'vue';
  export default defineComponent({
    props: {
      width: {
        type: String,
        default: '100%',
      },
    },
  });
</script>

<style scoped lang="scss">
  .wrapper {
    min-height: 100%;
  }

  .announcement {
    max-width: 50vw;
    margin: 0 auto;
  }

  .illustration-wrapper {
    margin-right: 20px;

    .i {
      transform: rotate(-90deg);
    }
  }
</style>
