
  import { defineComponent } from 'vue';
  import { mapActions } from 'pinia';
  import * as Sentry from '@sentry/browser';

  import { useAuthStore } from '@/pages/Oidc/store';

  /**
   * Supports the OIDC Front-Channel logout flow as specified here:
   * https://openid.net/specs/openid-connect-frontchannel-1_0-06.html
   */
  export default defineComponent({
    name: 'SilentLogout',
    methods: {
      ...mapActions(useAuthStore, ['silentLogout']),
    },
    async created() {
      try {
        await this.silentLogout();
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  });
