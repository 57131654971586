
  import { defineComponent, PropType } from 'vue';
  import { mdiCloseThick, mdiPause, mdiRadioTower, mdiStar } from '@mdi/js';
  import { RouteNames } from '@/router/types';
  import { StationStatus } from '@/api/station/types';

  export default defineComponent({
    name: 'StationListItem',
    props: {
      isFavourite: Boolean,
      stationId: String,
      defaultView: String,
      modeOfOperation: { type: String as PropType<StationStatus>, required: true },
      name: String,
      hasUnreadMessages: Boolean,
    },
    emits: ['click'],
    computed: {
      mdiStationIcon() {
        return mdiRadioTower;
      },
      mdiStarIcon() {
        return mdiStar;
      },
      link() {
        if (!this.defaultView) {
          return {
            name: RouteNames.StationMessages,
            params: {
              stationId: this.stationId,
            },
          };
        }

        return { name: RouteNames.View, params: { stationId: this.stationId, viewId: this.defaultView } };
      },
      cssClasses() {
        return {
          'item--deemphasize': [StationStatus.Inactive, StationStatus.Standby].includes(this.modeOfOperation),
        };
      },
      deEmphasizedStationIcon() {
        switch (this.modeOfOperation) {
          case StationStatus.Standby:
            return mdiPause;
          case StationStatus.Inactive:
            return mdiCloseThick;
          default:
            return null;
        }
      },
    },
  });
