import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_system_bar = _resolveComponent("v-system-bar")!

  return (_ctx.isDeprecated)
    ? (_openBlock(), _createBlock(_component_v_system_bar, {
        key: 0,
        class: _normalizeClass([{ 'system-bar--expanded': _ctx.isExpanded }, "system-bar"]),
        color: "orange-lighten-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_icon, { class: "mr-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mdiAlertIcon), 1)
            ]),
            _: 1
          }),
          _createElementVNode("span", {
            class: _normalizeClass({ 'text-truncate': !_ctx.isExpanded })
          }, _toDisplayString(_ctx.$t('App.DeprecatedBrowserMessage')), 3),
          (_ctx.$vuetify.display.smAndDown)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded)),
                size: "small",
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t('App.DeprecatedBrowserMessageCollapse')), 513), [
                    [_vShow, _ctx.isExpanded]
                  ]),
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t('App.DeprecatedBrowserMessageExpand')), 513), [
                    [_vShow, !_ctx.isExpanded]
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer)
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}