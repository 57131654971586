import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c9b4f77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile_kind-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    elevation: _ctx.elevation,
    rounded: _ctx.rounded,
    class: _normalizeClass([{ 'tile--padded-left': _ctx.kind }, "tile"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.kind)
          ? (_openBlock(), _createBlock(_component_v_tooltip, {
              key: 0,
              location: "right"
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_icon, _mergeProps(props, {
                  size: _ctx.iconSize,
                  class: "tile_kind",
                  color: "grey-lighten-2"
                }), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.kindIcon), 1)
                  ]),
                  _: 2
                }, 1040, ["size"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Dashboard.ViewKindTooltip', { kind: _ctx.kind })), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["elevation", "rounded", "class"]))
}