import { defineComponent, h } from 'vue';
import App from '@/pages/App/App.vue';
import AppStandalone from '@/pages/AppStandalone/AppStandalone.vue';

export default defineComponent({
  name: 'Root',
  data() {
    return {
      isStandalone: null as null | boolean,
    };
  },
  created() {
    const currentRoute = this.$route;
    this.isStandalone = currentRoute.query && currentRoute.query.standalone === 'true' ? true : false;
  },
  render() {
    if (this.isStandalone) {
      return h(AppStandalone);
    } else {
      return h(App);
    }
  },
});
