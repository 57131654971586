import { type HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { useAuthStore } from '@/pages/Oidc/store';

export const ClientServerConnectionChangedEvent = 'ClientServerConnectionChanged';
export enum WebsocketConnectionStatus {
  Disconnected = 'Disconnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
}

export class WebsocketClient {
  /**
   * Tracks the connected state. true = connected, false = disconnected, null = inactive (before first connect and after
   * destroy).
   */
  private connected: boolean | null = null;

  private reconnecting: boolean = false;

  private connectionObservers: Array<
    (connected: boolean | null, reconnecting: boolean, previousConnectedState: boolean | null) => void
  > = [];

  private programmaticallyClosed = false;

  public static Create(baseUrl: string) {
    const options = {
      accessTokenFactory: () => {
        const authStore = useAuthStore();
        return authStore.accessToken;
      },
      logger: LogLevel.Error,
    };

    const connection = new HubConnectionBuilder().withUrl(baseUrl, options).build();

    return new WebsocketClient(connection);
  }

  constructor(private connection: HubConnection) {
    this.connection
      .start()
      .then(() => this.connectionChanged(true))
      .catch(() => this.connectionChanged(false));

    this.connection.onclose(() => this.connectionChanged(this.programmaticallyClosed ? null : false));
  }

  public on(
    methodName: string,
    callback: (
      connected: boolean | null,
      reconnecting: boolean,
      previousConnectedState: boolean | null,
    ) => Promise<void>,
  ): WebsocketClient;
  public on<T>(methodName: string, callback: (...args: T[]) => Promise<void>): WebsocketClient;
  public on(methodName: string, callback: (...args: any[]) => Promise<void>): WebsocketClient {
    switch (methodName) {
      case ClientServerConnectionChangedEvent: {
        this.connectionObservers.push(callback);
        callback(this.connected, this.reconnecting, this.connected); // ensure that subscriber has connection status even when already connected

        return this;
      }
      default: {
        this.connection.on(methodName, callback);
        return this;
      }
    }
  }

  public async destroy(): Promise<void> {
    this.programmaticallyClosed = true; // avoid another reconnect

    await this.connection.stop();
  }

  private connectionChanged(connected: boolean | null) {
    const previousConnectedState = this.connected;
    this.connected = connected;

    switch (connected) {
      case true:
      case null:
        this.reconnecting = false;
        break;
      default:
        this.reconnecting = true;
        this.reconnect();
    }

    this.connectionObservers.forEach((o) => o(this.connected, this.reconnecting, previousConnectedState));
  }

  private reconnect(retryCount = 0) {
    if (this.connected || this.programmaticallyClosed) {
      return;
    }

    const backOffTime = 1000 * Math.pow(2, retryCount > 5 ? 5 : retryCount);

    this.connection
      .start()
      .then(() => this.connectionChanged(true))
      .catch(() => setTimeout(() => this.reconnect(retryCount + 1), backOffTime));
  }
}
