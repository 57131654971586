<template>
  <ErrorTemplate :error="error" />
</template>

<script>
  import { defineComponent } from 'vue';
  import ErrorTemplate from '@/common/components/Error/ErrorTemplate';
  import { HttpErrorKind } from '@/api/utils/HttpErrorEnums';
  import HttpError from '@/api/utils/HttpError';

  export default defineComponent({
    components: {
      ErrorTemplate,
    },
    data() {
      return { error: new HttpError(HttpErrorKind.ResourceNotFound, 'Resource Not Found') };
    },
  });
</script>

<style scoped lang="scss"></style>
