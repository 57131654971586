import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, {
    to: _ctx.link,
    density: "compact",
    class: "text-body-2",
    active: _ctx.isActiveView
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_v_badge, {
        "model-value": Boolean(_ctx.levelIcon),
        icon: _ctx.levelIcon,
        class: "viewList_badge",
        color: "primary-lighten-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.viewIcon), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value", "icon"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item_title, { class: "item--break text-body-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to", "active"]))
}