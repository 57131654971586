import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7503680d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "tools"
}
const _hoisted_3 = { class: "text-body-2 ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalEvents = _resolveComponent("GlobalEvents")!
  const _component_AutoUpdate = _resolveComponent("AutoUpdate")!
  const _component_KeyboardShortcut = _resolveComponent("KeyboardShortcut")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_StationListItem = _resolveComponent("StationListItem")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.stationsList.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_GlobalEvents, {
          onKeydown: _withKeys(_withModifiers(_ctx.onKeyboardEvent, ["exact","prevent"]), ["s"]),
          filter: _ctx.excludeFilter
        }, null, 8, ["onKeydown", "filter"]),
        (_ctx.hasSearchFocus)
          ? (_openBlock(), _createBlock(_component_GlobalEvents, {
              key: 0,
              onKeydown: _withKeys(_withModifiers(_ctx.onEscEvent, ["exact","prevent"]), ["esc"])
            }, null, 8, ["onKeydown"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AutoUpdate, {
          callback: _ctx.checkForNewMessages,
          interval: 300,
          enabled: ""
        }, null, 8, ["callback"]),
        (_ctx.showSearch || _ctx.hasInactiveStations)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.showSearch)
                ? (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 0,
                    ref: "stationsSearch",
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasSearchFocus = true)),
                    onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hasSearchFocus = false)),
                    "prepend-inner-icon": _ctx.mdiFilterIcon,
                    variant: "outlined",
                    density: "compact",
                    "hide-details": "",
                    clearable: "",
                    class: "mb-2",
                    color: "primary"
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('App.FilterStationsPlaceholder')) + " ", 1),
                      (_ctx.$vuetify.display.mdAndUp)
                        ? (_openBlock(), _createBlock(_component_KeyboardShortcut, {
                            key: 0,
                            class: "mx-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.hasSearchFocus ? 'esc' : 's'), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "prepend-inner-icon"]))
                : _createCommentVNode("", true),
              (_ctx.hasInactiveStations)
                ? (_openBlock(), _createBlock(_component_v_checkbox, {
                    key: 1,
                    modelValue: _ctx.showInactive,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showInactive) = $event)),
                    "hide-details": "",
                    class: "mt-0",
                    density: "compact",
                    color: "primary"
                  }, {
                    label: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('App.ShowInactiveStationsLabel')), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showSearch || _ctx.hasInactiveStations)
          ? (_openBlock(), _createBlock(_component_v_divider, { key: 2 }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_ctx.favorites.length)
              ? (_openBlock(), _createBlock(_component_v_list_subheader, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('App.FavouritesLabel')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favorites, ({ properties }) => {
              return (_openBlock(), _createBlock(_component_StationListItem, {
                key: properties.id,
                onClick: _ctx.resetForceStationsNavigation,
                "station-id": properties.id,
                name: properties.name,
                "has-unread-messages": properties.hasUnreadMessages,
                "default-view": properties.defaultView,
                "is-favourite": true,
                "mode-of-operation": properties.modeOfOperation
              }, null, 8, ["onClick", "station-id", "name", "has-unread-messages", "default-view", "mode-of-operation"]))
            }), 128)),
            _createVNode(_component_v_list_subheader, null, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('App.StationsLabel')), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonFavorites, ({ properties }) => {
              return (_openBlock(), _createBlock(_component_StationListItem, {
                key: properties.id,
                onClick: _ctx.resetForceStationsNavigation,
                "station-id": properties.id,
                name: properties.name,
                "has-unread-messages": properties.hasUnreadMessages,
                "default-view": properties.defaultView,
                "is-favourite": false,
                "mode-of-operation": properties.modeOfOperation
              }, null, 8, ["onClick", "station-id", "name", "has-unread-messages", "default-view", "mode-of-operation"]))
            }), 128))
          ]),
          _: 1
        }),
        (_ctx.showShowAllButton)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 3,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showAll = true)),
              color: "primary",
              class: "mx-auto d-block mb-4",
              size: "small",
              variant: "flat"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('App.ShowAllStationsLabel')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}