
  import { mapActions, mapState } from 'pinia';
  import { defineComponent } from 'vue';
  import { useRawDataExportStore } from '../store';
  import { mdiChevronDown, mdiChevronUp, mdiRefresh } from '@mdi/js';
  import RawDataExportList from '../components/RawDataExportList/RawDataExportList.vue';
  import RawDataExportPlaceOrder from '../components/RawDataExportPlaceOrder/RawDataExportPlaceOrder.vue';

  export default defineComponent({
    name: 'RawDataExportView',
    components: { RawDataExportList, RawDataExportPlaceOrder },
    props: {
      stationId: { type: String, required: true },
    },
    data() {
      return {
        showPlaceForm: false,
      };
    },
    computed: {
      mdiChevronDown() {
        return mdiChevronDown;
      },
      mdiChevronUp() {
        return mdiChevronUp;
      },
      mdiRefreshIcon() {
        return mdiRefresh;
      },
      ...mapState(useRawDataExportStore, ['getOrders']),
    },
    methods: {
      handleClose() {
        this.showPlaceForm = false;
      },
      togglePlaceOrderForm() {
        this.showPlaceForm = !this.showPlaceForm;
      },
      ...mapActions(useRawDataExportStore, ['fetchMeasurements', 'fetchOrders']),
    },
    mounted() {
      if (this.stationId) {
        this.fetchOrders(this.stationId);
        this.fetchMeasurements(this.stationId);
      }
    },
  });
