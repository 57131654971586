
  import { RawDataExportOrder } from '@/api/station';
  import { defineComponent, PropType } from 'vue';
  import RawDataExportListItem from '../RawDataExportListItem/RawDataExportListItem.vue';

  export default defineComponent({
    name: 'RawDataExportList',
    components: { RawDataExportListItem },
    props: {
      stationId: { type: String, required: true },
      orders: { type: Array as PropType<RawDataExportOrder[]>, required: true },
    },
  });
