// Color Palette from:
// https://material.io/resources/color/#!/?view.left=1&view.right=0&secondary.color=e54e62&primary.color=bbcbd3&secondary.text.color=FAFAFA

export const primary = {
  main: '#90a5bd',
  light: '#eefeff',
  dark: '#90a5bd',
};

export const secondary = {
  main: '#e44d61',
  light: '#ff808e',
  dark: '#ac0d37',
};

export const text = {
  onPrimary: '#000',
  onSecondary: '#fafafa',
};

// custom colors
export const background = '#f2f2f2';
export const listitem = '#f8f8f8';
export const listitemhover = '#f2f2f2';

// overrides
export const darkSurface = '#233846'; // primary-darken-4
