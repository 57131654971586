import { keyBy } from 'lodash-es';
import { defineStore } from 'pinia';

import type {
  IAddStationMessagesMutation,
  IFetchStationMessagesPayload,
  IMarkAllMessagesReadPayload,
  IPublishStationMessagePayload,
  IRefreshStationMessagesPayload,
  IReplaceMessagesMutation,
  IStationMessagesState,
} from '@/areas/StationMessages/store/types';
import type { IMarkStationAsReadPayload } from '@/areas/Station/store/types';

import { getStationMessages, markStationMessagesRead, publishStationMessage } from '@/api/station/station.api';
import UserFriendlyError from '@/common/utils/UserFriendlyError';
import { useStationStore } from '@/areas/Station/store';
import { useRootStore } from '@/store';

export const useStationMessagesStore = defineStore('stationMessages', {
  state: (): IStationMessagesState => ({ messagesById: {}, messagesIds: [], totalCount: 0, stationId: null }),
  getters: {
    stationMessagesById(state) {
      return state.messagesById;
    },
    stationMessagesIds(state) {
      return state.messagesIds;
    },
    hasMoreMessages(state) {
      return state.totalCount > state.messagesIds.length;
    },
  },
  actions: {
    async fetchStationMessages({ stationId, offset, limit }: IFetchStationMessagesPayload): Promise<void> {
      const rootStore = useRootStore();

      try {
        const { data, totalCount } = await getStationMessages(stationId, limit, offset);
        this.addStationMessages({
          totalCount,
          messages: data,
          stationId,
        } as IAddStationMessagesMutation);
      } catch (e) {
        rootStore.setError({ error: new UserFriendlyError('StationMessages.Errors.FailedToFetch'), intrusive: false });
      }
    },
    async refreshStationMessages({ stationId }: IRefreshStationMessagesPayload): Promise<void> {
      const rootStore = useRootStore();

      try {
        const { data, totalCount } = await getStationMessages(stationId);

        this.replaceStationMessages({
          totalCount,
          messages: data,
          stationId,
        } as IReplaceMessagesMutation);
      } catch (e) {
        rootStore.setError({ error: new UserFriendlyError('StationMessages.Errors.FailedToFetch'), intrusive: false });
      }
    },
    async markAllMessagesRead({ stationId }: IMarkAllMessagesReadPayload): Promise<void> {
      const stationStore = useStationStore();
      const rootStore = useRootStore();

      // first mark all messages read
      try {
        await markStationMessagesRead(stationId);
        // then fetch the latest results,
        // and mark station as read
        await Promise.all([
          this.refreshStationMessages({
            stationId,
          } as IRefreshStationMessagesPayload),
          stationStore.markStationAsRead({
            stationId,
          } as IMarkStationAsReadPayload),
        ]);
      } catch (e) {
        rootStore.setError({
          error: new UserFriendlyError('StationMessages.Errors.FailedToMarkAsRead'),
          intrusive: false,
        });
      }
    },
    async publishStationMessage({ isInternal, stationId, message }: IPublishStationMessagePayload): Promise<void> {
      const rootStore = useRootStore();

      try {
        const result = await publishStationMessage(stationId, message, isInternal);

        this.addStationMessages({
          totalCount: this.totalCount,
          messages: [result],
          stationId,
        } as IAddStationMessagesMutation);

        await this.refreshStationMessages({
          stationId,
        } as IRefreshStationMessagesPayload);
      } catch (e) {
        rootStore.setError({
          error: new UserFriendlyError('StationMessages.Errors.FailedToPublish'),
          intrusive: false,
        });

        throw e; // propagate error
      }
    },
    /**
     * Use when paginating messages.
     * Retains existing messages and avoids redrawing them.
     */
    addStationMessages({ totalCount, messages, stationId }: IAddStationMessagesMutation) {
      if (this.stationId !== stationId) {
        // clear previous station messages when station changes
        this.messagesIds = [];
        this.messagesById = {};
        this.stationId = stationId;
      }

      const ids = [...this.messagesIds];
      const messagesByIds = { ...this.messagesById };

      messages.forEach((message) => {
        const { id } = message;

        if (!ids.includes(id)) {
          ids.push(id);
        }

        messagesByIds[id] = message;
      });

      this.totalCount = totalCount;
      this.messagesIds = ids;
      this.messagesById = messagesByIds;
    },
    /**
     * Use when initially loading or refreshing message's state.
     * Redraws all messages.
     */
    replaceStationMessages({ stationId, messages, totalCount }: IReplaceMessagesMutation) {
      this.totalCount = totalCount;
      this.messagesIds = messages.map(({ id }) => id);
      this.messagesById = keyBy(messages, ({ id }) => id);
      this.stationId = stationId;
    },
  },
});
