
  import { defineComponent } from 'vue';
  import { mapState } from 'pinia';
  import { mdiOpenInNew } from '@mdi/js';
  import { RouteNames } from '@/router/types';

  import Logo from '@/common/components/Logo/Logo.vue';
  import NavigationUserInfo from '@/pages/Navigation/components/NavigationUserInfo/NavigationUserInfo.vue';
  import StationsNavigation, {
    StationsNavigationComponentName,
  } from '@/pages/Navigation/components/StationsNavigation/StationsNavigation.vue';
  import ViewNavigation from '@/pages/Navigation/components/ViewNavigation/ViewNavigation.vue';
  import PartnerLogo from '@/pages/Navigation/components/PartnerLogo/PartnerLogo.vue';
  import { useStationStore } from '@/areas/Station/store';
  import { useRootStore } from '@/store';

  export default defineComponent({
    data() {
      return {
        drawer: true,
        drawerWidth: 300,
        forceShowStations: false,
      };
    },
    props: {
      isVisible: {
        type: Boolean,
      },
      user: Object,
      logout: Function,
      login: Function,
    },
    emits: ['toggle:drawer'],
    watch: {
      isVisible: {
        handler: function (newVal: boolean): void {
          this.drawer = newVal;
        },
        immediate: true,
      },
      '$route.query': {
        handler(newVal: any) {
          if (newVal.forceShowStations === 'true') {
            this.forceShowStations = true;
          }
        },
        immediate: true,
      },
    },
    methods: {
      toggleDrawer(value: boolean): void {
        this.$emit('toggle:drawer', value);
      },
      onNavigate() {
        if (this.$vuetify.display.mdAndDown) {
          // when drawer is floating, navigation changes should close the drawer
          this.toggleDrawer(false);
        }
      },
    },
    computed: {
      mdiOpenInNewIcon() {
        return mdiOpenInNew;
      },
      navToShow() {
        if (this.isOrphanStation) {
          // users with access to only 1 station never have to see stations list
          return ViewNavigation;
        }

        if (this.forceShowStations) {
          return StationsNavigation;
        }

        switch (this.$route.name) {
          case RouteNames.View:
          case RouteNames.StationMessages:
          case RouteNames.ImageSource:
          case RouteNames.StationInfo:
          case RouteNames.StationSms:
          case RouteNames.Export:
          case RouteNames.MessengerListEditor:
            return ViewNavigation;
          default:
            return StationsNavigation;
        }
      },
      isOrphanStation() {
        return this.stationsList?.length === 1;
      },
      stationsNavigationComponentName() {
        return StationsNavigationComponentName;
      },
      oldDataPortalBaseUrl() {
        return runtimeAppSettings.oldDataPortalBaseUrl;
      },
      enableStationFavourites() {
        return (this.stationsList?.length || 0) >= 10;
      },
      customization() {
        return this.getCustomization();
      },
      ...mapState(useStationStore, ['stationsList']),
      ...mapState(useRootStore, ['impersonator', 'getCustomization']),
    },
    components: {
      PartnerLogo,
      Logo,
      NavigationUserInfo,
    },
  });
