import { asyncImportHandler } from '@/router/utils/async-import-handler';

export const supportedLocales = ['en', 'de', 'fr', 'it', 'es', 'no'];
export const localeStorageKey = 'gp.userLanguage';
export const fallbackLocale = 'en';

export function getBrowserLocale(options: { countryCodeOnly?: boolean } = {}): string | undefined {
  const defaultOptions = { countryCodeOnly: true };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();

  return trimmedLocale;
}

export function getConfiguredLocale(): string | null {
  const locale = window.localStorage.getItem(localeStorageKey);

  if (locale) {
    return locale.trim();
  }

  return null;
}

export function setConfiguredLocale(locale: string): void {
  window.localStorage.setItem(localeStorageKey, locale);

  const redirectTo = `${runtimeAppSettings.rootUrl}`;

  // switching locale requires a reload, to fetch new locale file
  window.location.replace(redirectTo);
}

export function getStartingLocale(): string {
  const browserLocale = getBrowserLocale();
  const configuredLocale = getConfiguredLocale();

  if (configuredLocale && supportedLocales.includes(configuredLocale)) {
    return configuredLocale;
  }

  if (browserLocale && supportedLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return fallbackLocale;
}

export function loadLocale(localeToLoad: string) {
  return asyncImportHandler(import(`@/locales/${localeToLoad}-flat.json`));
}
