
  import { defineComponent } from 'vue';
  import CustomTransition from '@/common/components/CustomTransition/CustomTransition.vue';

  export default defineComponent({
    name: 'Passthrough',
    components: {
      CustomTransition,
    },
  });
