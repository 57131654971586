import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_FormattedMessage = _resolveComponent("FormattedMessage")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isActive,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
    persistent: "",
    "max-width": "600px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "info" }, {
            title: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: _ctx.mdiInfo,
                size: "small",
                color: "white",
                start: ""
              }, null, 8, ["icon"]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('App.DeprecatedRedirect.Title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_FormattedMessage, {
                tag: "p",
                id: "App.DeprecatedRedirect.Paragraph1",
                values: { redirectSource: _ctx.redirectSource },
                class: "mb-3"
              }, {
                code: _withCtx((Content) => [
                  _createElementVNode("code", null, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Content)))
                  ])
                ]),
                _: 1
              }, 8, ["values"]),
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('App.DeprecatedRedirect.Paragraph2')), 1),
              _createVNode(_component_FormattedMessage, {
                tag: "p",
                id: "App.DeprecatedRedirect.Paragraph3",
                values: { domain: _ctx.domain },
                class: "mb-3"
              }, {
                strong: _withCtx((Content) => [
                  _createElementVNode("strong", null, [
                    _createElementVNode("code", null, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Content)))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["values"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "text",
                onClick: _ctx.onAcknowledge
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('App.DeprecatedRedirect.AcknowledgeButton')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}