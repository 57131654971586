import { RouteLocationNormalized } from 'vue-router';

import { useStationMessagesStore } from '@/areas/StationMessages/store';

export const stationMessagesRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) => {
  const { params } = to;
  const { stationId } = params;
  const store = useStationMessagesStore();

  await store.refreshStationMessages({
    stationId: stationId as string,
  });

  next();
};
