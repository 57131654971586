
  import { defineComponent } from 'vue';
  import { mapActions } from 'pinia';
  import * as Sentry from '@sentry/browser';
  import { isNavigationFailure } from 'vue-router';
  import { mdiLockClock } from '@mdi/js';

  import Spinner from '@/common/components/Spinner/Spinner.vue';
  import UserFriendlyError from '@/common/utils/UserFriendlyError';
  import { RouteNames } from '@/router/types';
  import Announcement from '@/common/components/Announcement/Announcement.vue';
  import { useRootStore } from '@/store';
  import { useAuthStore } from '@/pages/Oidc/store';
  import { type UserState } from '@/pages/Oidc/utils/AuthService';

  export default defineComponent({
    components: {
      Announcement,
      Spinner,
    },
    data() {
      return {
        isExpired: false,
      };
    },
    computed: {
      expiredIcon() {
        return mdiLockClock;
      },
    },
    methods: {
      ...mapActions(useRootStore, ['setError']),
      ...mapActions(useAuthStore, ['handleLoginCallback']),
    },
    async mounted() {
      try {
        const user = await this.handleLoginCallback().catch((e) => {
          // deal with user navigating back to oidc redirect,
          // in which case user may be logged in and signInCallback would fail due to un-matching local state
          if (e.message === 'No matching state found in storage') {
            this.isExpired = true;
            return;
          }

          if (e.message === 'No state in response') {
            // may occur if user navigates to /oidc-callback directly without state in query string
            this.isExpired = true;
            return;
          }

          throw e;
        });

        const redirectPath = (user?.state as UserState)?.redirectPath;

        if (redirectPath) {
          await this.$router.replace(redirectPath).catch((e) => {
            // discard expected navigation errors, like navigation guards that do redirects
            // https://v3.router.vuejs.org/guide/advanced/navigation-failures.html
            if (isNavigationFailure(e)) {
              return;
            }

            throw e;
          });
        }
      } catch (e) {
        const userFriendlyError = new UserFriendlyError(this.$t('Error.LoginFailure') as string, (e as Error).message);

        await this.$router.push({
          name: RouteNames.Error,
        });

        this.setError({ error: userFriendlyError, intrusive: true });

        Sentry.captureException(e);
      }
    },
  });
