
  import { defineComponent } from 'vue';
  import RawDataExportMeasurementChooser from '../RawDataExportMeasurementChooser/RawDataExportMeasurementChooser.vue';
  import DateRangeSelector from '@/common/components/DateRangeSelector/DateRangeSelector.vue';
  import { useRawDataExportStore } from '../..';
  import { mapActions, mapState } from 'pinia';
  import { subDays } from 'date-fns';
  import { useRootStore } from '@/store';
  import { useStationStore } from '@/areas/Station/store';

  export default defineComponent({
    name: 'RawDataExportPlaceOrder',
    components: { DateRangeSelector, RawDataExportMeasurementChooser },
    emits: ['closed'],
    props: { stationId: { type: String, required: true } },
    data() {
      return {
        from: subDays(new Date(), 7),
        to: new Date(),
        selectedMeasurementIds: [] as string[],
        dateRangeValid: true,
        measurementIdsValid: true,
      };
    },
    computed: {
      timeZone() {
        return this.getStationTimeZone(this.stationId);
      },
      isFormValid() {
        return this.dateRangeValid && this.measurementIdsValid;
      },
      ...mapState(useStationStore, ['getStationTimeZone']),
      ...mapState(useRootStore, ['locale']),
      ...mapState(useRawDataExportStore, ['getMeasurements']),
    },
    methods: {
      onClose() {
        this.$emit('closed');
      },
      onDateRangeChange({ from, to }: { timeZone: string; from: Date; to: Date }) {
        this.from = from;
        this.to = to;
      },
      onDateRangeValidChanged(valid: boolean) {
        this.dateRangeValid = valid;
      },
      onMeasurementIdsChange(ids: string[]) {
        this.selectedMeasurementIds = ids;
        this.validateMeasurementIds();
      },
      validateMeasurementIds() {
        if (this.selectedMeasurementIds && this.selectedMeasurementIds.length > 0) {
          this.measurementIdsValid = true;
        } else {
          this.measurementIdsValid = false;
        }
      },
      async onSubmit() {
        this.validateMeasurementIds();
        if (this.isFormValid) {
          const formData = {
            stationId: this.stationId,
            measurementIds: this.selectedMeasurementIds,
            from: this.from,
            to: this.to,
          };
          await this.placeOrder(formData).then(() => this.onClose());
        }
      },
      ...mapActions(useRawDataExportStore, ['placeOrder']),
    },
  });
