
  import { defineComponent } from 'vue';

  import HttpError from '@/api/utils/HttpError';
  import { HttpErrorKind } from '@/api/utils/HttpErrorEnums';

  /**
   * @example [none]
   */
  export default defineComponent({
    name: 'HttpErrorMessage',
    props: {
      error: Error,
    },
    emits: ['reset'],
    computed: {
      errorText(): string {
        return this.$t('Error.TextForCode', { code: (this.error as HttpError).status }) as string;
      },
    },
    methods: {
      onOnline() {
        this.$emit('reset');
      },
    },
    created(): void {
      if ((this.error as HttpError).status === HttpErrorKind.ConnectionTimeout) {
        window.addEventListener('online', this.onOnline);
      }
    },
    beforeUnmount(): void {
      if ((this.error as HttpError).status === HttpErrorKind.ConnectionTimeout) {
        window.removeEventListener('online', this.onOnline);
      }
    },
  });
