import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RawDataExportListItem = _resolveComponent("RawDataExportListItem")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_ctx.orders.length > 0)
    ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, id) => {
            return (_openBlock(), _createBlock(_component_RawDataExportListItem, {
              key: id,
              stationId: _ctx.stationId,
              order: order
            }, null, 8, ["stationId", "order"]))
          }), 128))
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('RawDataExport.NoOrdersText')), 1))
}