import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_LabeledDivider = _resolveComponent("LabeledDivider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-grow-1", { 'my-4': _ctx.$vuetify.display.smAndUp, 'my-1': _ctx.$vuetify.display.xs }])
  }, [
    _createVNode(_component_v_form, {
      modelValue: _ctx.isFormValid,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isFormValid) = $event)),
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.selectedFromDateDisplayFormat,
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedFromDateDisplayFormat = $event.target.value)),
                  rules: _ctx.fromDateRules,
                  hint: String(_ctx.$t('DateRangeSelector.Controls.DateFilterFormatHelpText')),
                  label: String(_ctx.$t('DateRangeSelector.Controls.DateFilterFromLabel')),
                  "validate-on": "blur",
                  density: "comfortable",
                  variant: "filled",
                  class: "text-body-2",
                  color: "primary"
                }, null, 8, ["modelValue", "rules", "hint", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.selectedToDateDisplayFormat,
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedToDateDisplayFormat = $event.target.value)),
                  rules: _ctx.toDateRules,
                  hint: String(_ctx.$t('DateRangeSelector.Controls.DateFilterFormatHelpText')),
                  label: String(_ctx.$t('DateRangeSelector.Controls.DateFilterToLabel')),
                  "validate-on": "blur",
                  density: "comfortable",
                  variant: "filled",
                  class: "text-body-2",
                  color: "primary"
                }, null, 8, ["modelValue", "rules", "hint", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_LabeledDivider, {
      text: String(_ctx.$t('DateRangeSelector.Controls.DateFilterDividerText')),
      thickness: 1,
      class: "text-body-2 text-grey-darken-1 mt-n2"
    }, null, 8, ["text"]),
    _createVNode(_component_Datepicker, {
      ref: "datePicker",
      modelValue: _ctx.pickerDates,
      "onUpdate:modelValue": [
        _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pickerDates) = $event)),
        _ctx.handlePickerChange
      ],
      "partial-range": false,
      "enable-time-picker": false,
      locale: _ctx.locale,
      "model-type": _ctx.pickerFormat,
      "month-change-on-scroll": false,
      inline: _ctx.$vuetify.display.smAndUp,
      "teleport-center": _ctx.$vuetify.display.xs,
      class: "text-body-2",
      "month-name-format": "long",
      range: "",
      "auto-apply": "",
      "min-date": "1990-01-01",
      offset: "0"
    }, _createSlots({ _: 2 }, [
      (_ctx.$vuetify.display.xs)
        ? {
            name: "trigger",
            fn: _withCtx(() => [
              _createVNode(_component_v_btn, {
                size: "x-small",
                color: "primary",
                elevation: "0",
                class: "d-block mx-auto"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('DateRangeSelector.Controls.OpenCalendarText')), 1)
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["modelValue", "onUpdate:modelValue", "locale", "model-type", "inline", "teleport-center"])
  ], 2))
}