import { NavigationGuard, RouteLocationNormalized } from 'vue-router';

export const asyncRouteGuard =
  (fn: NavigationGuard) => (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) =>
    Promise.resolve(fn(to, from, next)).catch((e) => {
      // annotate error with where user came from/went to.
      // allows to make better decisions on how to render the error (ex. standalone=true mode)
      if (typeof e === 'object') {
        e.from = from;
        e.to = to;
      }
      next(e);
    });
