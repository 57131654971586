
  import { defineComponent } from 'vue';
  import {
    mdiCctv,
    mdiChartLine,
    mdiContentPaste,
    mdiDatabaseOutline,
    mdiImageOutline,
    mdiLinkBoxVariantOutline,
    mdiLinkVariant,
    mdiLockAlert,
    mdiMap,
    mdiMapSearch,
    mdiMonitorDashboard,
    mdiShieldLock,
    mdiViewDashboardOutline,
  } from '@mdi/js';
  import { isEqual } from 'lodash-es';

  import { RouteNames } from '@/router/types';
  import { LegacyUserLevel, SupportedViewKinds } from '@/api/station/types';

  export default defineComponent({
    name: 'ViewListItem',
    props: {
      kind: {},
      title: String,
      stationId: String,
      viewId: String,
      requiredUserLevel: {},
    },
    computed: {
      link() {
        return { name: RouteNames.View, params: { stationId: this.stationId, viewId: this.viewId } };
      },
      viewIcon() {
        switch (this.kind) {
          case SupportedViewKinds.Chart:
            return mdiChartLine;
          case SupportedViewKinds.ImageViewer:
            return mdiImageOutline;
          case SupportedViewKinds.ObservationMap:
            return mdiMapSearch;
          case SupportedViewKinds.ThermistorChain:
            return mdiLinkBoxVariantOutline;
          case SupportedViewKinds.Dashboard:
            return mdiViewDashboardOutline;
          case SupportedViewKinds.MeasurementMap:
            return mdiMap;
          case SupportedViewKinds.Html:
            return mdiContentPaste;
          case SupportedViewKinds.Raw:
            return mdiDatabaseOutline;
          case SupportedViewKinds.ControlCenter:
          case SupportedViewKinds.RaumaControlCenter:
            return mdiMonitorDashboard;
          case SupportedViewKinds.ExternalLink:
            return mdiLinkVariant;
          case SupportedViewKinds.DataloggerControl:
          case SupportedViewKinds.LiveImage:
            return mdiCctv;
          default:
            return '';
        }
      },
      levelIcon() {
        switch (this.requiredUserLevel) {
          case LegacyUserLevel.Administrator:
            return mdiShieldLock;
          case LegacyUserLevel.ChiefUser:
          case LegacyUserLevel.Manager:
          case LegacyUserLevel.ProjectLeader:
            return mdiLockAlert;
          default:
            return '';
        }
      },
      isActiveView() {
        const { name, params } = this.$route;
        return isEqual({ name, params }, this.link);
      },
    },
  });
