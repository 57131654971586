import { UserManager, type UserManagerSettings } from 'oidc-client-ts';

export interface UserState {
  redirectPath?: string;
}

/*
 * Supported event names for oidc-client-ts's UserManagerEvents,
 * which are actually named methods like 'addUserLoaded', 'removeUserLoaded', etc.
 */
export type OidcClientEventNames = 'userSignedOut' | 'userLoaded';

export class AuthService {
  private userManager: UserManager;

  constructor(config: UserManagerSettings) {
    this.userManager = new UserManager(config);
  }

  public async login(redirectPath?: string) {
    return this.userManager.signinRedirect({
      state: {
        redirectPath,
      },
    });
  }

  public async logout() {
    return this.userManager.signoutRedirect();
  }

  /**
   * Used for OIDC Front-Channel logout
   */
  public async removeUser() {
    return this.userManager.removeUser();
  }

  public async handleLoginCallback() {
    return this.userManager.signinCallback();
  }

  public async getUser() {
    return this.userManager.getUser();
  }

  public on(eventName: OidcClientEventNames, eventListener: () => void) {
    const addFnName = `add${this.firstLetterUppercase(eventName)}`;

    if (addFnName in this.userManager.events && typeof eventListener === 'function') {
      (this.userManager.events as any)[addFnName](eventListener);
    }
  }

  private firstLetterUppercase(str: string) {
    return str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }
}
