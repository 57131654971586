import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event))),
    class: _normalizeClass([_ctx.cssClasses, "stationList text-body-2"]),
    to: _ctx.link,
    density: "compact",
    exact: ""
  }, _createSlots({
    prepend: _withCtx(() => [
      _createVNode(_component_v_badge, {
        "model-value": _ctx.hasUnreadMessages,
        color: "red",
        dot: "",
        "data-testid": "newMessagesIndicator",
        floating: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mdiStationIcon), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value"]),
      (_ctx.deEmphasizedStationIcon)
        ? (_openBlock(), _createBlock(_component_v_badge, {
            key: 0,
            icon: _ctx.deEmphasizedStationIcon,
            color: "grey-lighten-1",
            "offset-y": "7",
            bordered: ""
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item_title, {
        class: _normalizeClass([{ 'item--has-messages': _ctx.hasUnreadMessages }, "item--break text-body-2"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 2
  }, [
    (_ctx.isFavourite)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_v_icon, { color: "yellow-darken-2 ml-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.mdiStarIcon), 1)
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["class", "to"]))
}