import { createIntl as _createIntl } from 'vue-intl';

import { getStartingLocale, loadLocale } from '@/plugins/i18n/utils';

export const locale = getStartingLocale();
export const getMessages = (localeToLoad: string = locale) => loadLocale(localeToLoad);

export const createIntl = async () =>
  _createIntl({
    locale,
    messages: await getMessages(locale),
  });
