import { parseISO } from 'date-fns';
import {
  IStationMessageDto,
  IStationMessagesResponseDto,
  RawDataExportOrder,
  RawDataExportOrderDto,
  RawDataExportOrderProgress,
} from '@/api/station/types';
import { IStationMessage, IStationMessagesResponse } from '@/areas/StationMessages/store/types';

export const hydrateStationMessages = ({ data, totalCount }: IStationMessagesResponseDto): IStationMessagesResponse => {
  if (!data || !data.length) {
    return {
      data: [],
      totalCount: totalCount || 0,
    };
  }

  return {
    data: data.map((message) => {
      const { timestamp, readAt } = message;
      const newMessage: IStationMessage = {
        ...message,
        timestamp: parseISO(timestamp),
        readAt: null,
      };

      if (readAt) {
        newMessage.readAt = parseISO(readAt);
      }

      return Object.freeze(newMessage);
    }),
    totalCount,
  };
};

export const hydrateSingleStationMessage = (message: IStationMessageDto): IStationMessage => {
  const { timestamp, readAt } = message;
  return Object.freeze({
    ...message,
    timestamp: parseISO(timestamp),
    readAt: readAt ? parseISO(readAt) : null,
  });
};

export const mapRawDataExportOrder = (order: RawDataExportOrderDto): RawDataExportOrder => {
  const { timestamp, exportFrom, exportTo, progress } = order;
  return {
    ...order,
    progress: progress as RawDataExportOrderProgress,
    timestamp: parseISO(timestamp),
    exportFrom: parseISO(exportFrom),
    exportTo: parseISO(exportTo),
  };
};
