
  import { defineComponent } from 'vue';
  import { mdiAlertOutline } from '@mdi/js';

  export default defineComponent({
    data() {
      return { isDeprecated: false, isExpanded: false };
    },
    computed: {
      mdiAlertIcon() {
        return mdiAlertOutline;
      },
    },
    async mounted() {
      const { default: BrowserUpdate } = await import('browser-update/update.npm.js').catch(() => ({
        __esModule: true,
        default: null,
      }));

      if (!BrowserUpdate) {
        return;
      }

      BrowserUpdate({
        required: {
          /**
           * @see [README](/README.md#warning-for-deprecated-browsers)
           * Update the corresponding README section as well if you update the following list:
           */
          e: 88,
          f: 86,
          c: 90,
          s: 14,
          ios: 15,
        },
        api: 2022.06,
        nomessage: true,
        reminder: 0,
        onshow: () => {
          this.isDeprecated = true;
        },
      });
    },
  });
