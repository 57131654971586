import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19ec4034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "labeled-divider_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "labeled-divider",
    style: _normalizeStyle({ 'border-bottom-width': `${_ctx.thickness}px` })
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 4))
}