import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReturnToMobileApp = _resolveComponent("ReturnToMobileApp")!
  const _component_CustomTransition = _resolveComponent("CustomTransition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_FullHeight = _resolveComponent("FullHeight")!

  return (_openBlock(), _createBlock(_component_FullHeight, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_ctx.$route.query.standalone)
            ? (_openBlock(), _createBlock(_component_ReturnToMobileApp, {
                key: 0,
                returnUrl: _ctx.$route.query.closeUrl
              }, null, 8, ["returnUrl"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_container, {
            class: "wrapper",
            fluid: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.params.viewId
              }, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_CustomTransition, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            error: _ctx.error,
            "error-is-intrusive": _ctx.errorIsIntrusive,
            setError: _ctx.setError
          }, null, 8, ["error", "error-is-intrusive", "setError"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}