import { RouteLocationNormalized } from 'vue-router';

export interface RouteError extends Error {
  from?: RouteLocationNormalized;
  to?: RouteLocationNormalized;
}

export enum RouteNames {
  Home = 'home',
  Support = 'support',
  Oidc = 'oidcCallback',
  Logout = 'logout',
  SilentLogout = 'silent-logout',
  Profile = 'profile',
  Stations = 'stations',
  Station = 'station',
  StationMessages = 'messages',
  StationInfo = 'info',
  MessengerListEditor = 'messenger-editor',
  StationSms = 'sms',
  Export = 'export',
  View = 'view',
  ImageSource = 'image-viewer-by-source',
  Error = 'error',
  four04 = '404',
}
