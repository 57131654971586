
  import { defineComponent } from 'vue';

  /**
   * @example [none]
   */
  export default defineComponent({
    name: 'GenericErrorMessage',
    props: {
      error: Error,
    },
    computed: {
      errorText(): string {
        return this.$t('Error.GenericErrorMessageText') as string;
      },
    },
  });
