import httpClient from './httpClient';
import {
  ViewResponse,
  IStationDto,
  TimeSeriesResponse,
  IThermistorSeriesResponse,
  ISeriesResponse,
  INavigatorSerie,
  IStationsResponseDto,
  IStationMessagesResponseDto,
  IStationMessageDto,
  IFavouriteStationsResponseDto,
  RawDataExportOrdersResponse,
  MeasurementsResponse,
  RawDataExportOrdersResponseDto,
  NavigatorSeriesRange,
} from './types';
import { IStationMessage, IStationMessagesResponse } from '@/areas/StationMessages/store/types';
import { hydrateSingleStationMessage, hydrateStationMessages, mapRawDataExportOrder } from './utils';
import { parseISO } from 'date-fns';
import { scaleUtc } from 'd3-scale';

const RESOURCE = '/stations';

export const getViewInfo = async (viewId: string, stationId: string): Promise<ViewResponse> => {
  const result = await httpClient.get(`${RESOURCE}/${stationId}/views/${viewId}`);
  return result.data;
};

export const getStations = async (): Promise<IStationsResponseDto> => {
  const result = await httpClient.get<IStationsResponseDto>(`${RESOURCE}`);
  return result.data;
};

export const getStationInfo = async (stationId: string): Promise<IStationDto> => {
  const result = await httpClient.get(`${RESOURCE}/${stationId}`);
  return result.data;
};

export const getTimeSeries = async (
  viewId: string,
  stationId: string,
  from: string,
  to: string,
  signal?: AbortSignal,
): Promise<TimeSeriesResponse> => {
  const result = await httpClient.get(`${RESOURCE}/${stationId}/views/${viewId}/series`, {
    params: { from, to },
    signal,
  });
  return result.data;
};

export const getThermistorChainData = async (
  viewId: string,
  stationId: string,
  from: string,
  to: string,
): Promise<IThermistorSeriesResponse> => {
  const result = await httpClient.get(`${RESOURCE}/${stationId}/views/${viewId}/series/thermistor`, {
    params: { from, to },
  });
  return result.data;
};

export const getNavigatorSeries = async (
  stationId: string,
  viewId: string,
  signal?: AbortSignal,
): Promise<ISeriesResponse<INavigatorSerie>> => {
  /** Instead of querying the /series/navigator endpoint that often times out, we only retrieve the series start and end date,
   * the domain. With the extent we construct a pseudo navigator object with D3 and add approximately ten ticks by heuristic,
   * but including the min and max ticks explicitly. The scale represents the full date range of the chart's series,
   * and they have NaN as value so that no line is drawn by Highcharts. */
  const result = await httpClient.get<NavigatorSeriesRange>(`${RESOURCE}/${stationId}/views/${viewId}/series/range`, {
    signal,
  });
  const startDate = parseISO(result.data.minTimestamp);
  const endDate = parseISO(result.data.maxTimestamp);
  const ticks = createNavigatorTicks(startDate, endDate);

  return {
    series: [{ data: ticks }],
    lastMeasurementAt: result.data.maxTimestamp,
  };
};

export const createNavigatorTicks = (startDate: Date, endDate: Date) => {
  const timeScale = scaleUtc([startDate, endDate], [0, 1]);
  const ticks = timeScale
    .ticks()
    .concat(timeScale.domain())
    .sort((a, b) => a.valueOf() - b.valueOf())
    .map((d) => [d.valueOf(), NaN]);
  return ticks;
};

export const getStationMessages = async (
  stationId: string,
  limit: number = 30,
  offset: number = 0,
): Promise<IStationMessagesResponse> => {
  const result = await httpClient.get<IStationMessagesResponseDto>(`${RESOURCE}/${stationId}/messages`, {
    params: { limit, offset },
  });
  return hydrateStationMessages(result.data);
};

export const markStationMessagesRead = async (stationId: string): Promise<void> => {
  await httpClient.put<void>(`${RESOURCE}/${stationId}/messages/mark-read`);
};

export const publishStationMessage = async (
  stationId: string,
  message: string,
  isInternal: boolean,
): Promise<IStationMessage> => {
  const result = await httpClient.post<IStationMessageDto>(`${RESOURCE}/${stationId}/messages`, {
    message,
    isInternal,
  });
  return hydrateSingleStationMessage(result.data);
};

export const getFavouriteStations = async (): Promise<IFavouriteStationsResponseDto> => {
  const result = await httpClient.get<IFavouriteStationsResponseDto>(`/favourite-stations`);
  return result.data;
};

export const addFavouriteStation = async (stationId: string): Promise<void> => {
  await httpClient.put(`/favourite-stations/${stationId}`);
};

export const removeFavouriteStation = async (stationId: string): Promise<void> => {
  await httpClient.delete(`/favourite-stations/${stationId}`);
};

export const getRawDataExportOrders = async (stationId: string): Promise<RawDataExportOrdersResponse> => {
  const { data } = await httpClient.get<RawDataExportOrdersResponseDto>(
    `${RESOURCE}/${stationId}/raw-data-export-orders`,
  );
  return data.exportOrders.map(mapRawDataExportOrder);
};

export const placeRawDataExportOrder = async (
  stationId: string,
  timestamp: string,
  measurements: string[],
  exportFrom: string,
  exportTo: string,
): Promise<void> =>
  await httpClient.post(`${RESOURCE}/${stationId}/raw-data-export-orders`, {
    timestamp,
    measurements,
    exportFrom,
    exportTo,
  });

/**
 * Get id and name of the specified measurement and station ids.
 * At least one of either params has to be set, otherise an error is thrown.
 *
 * @param stationIds Station id(s) as Array<string>
 * @param measurementIds Measurement id(s) as Array<string>
 * @returns String id and name for an array of measurements.
 */
export const getMeasurements = async (
  stationIds?: string[],
  measurementIds?: string[],
): Promise<MeasurementsResponse> => {
  if ((stationIds == null && measurementIds == null) || (stationIds?.length === 0 && measurementIds?.length === 0)) {
    throw Error('API method getMeasurements must have either `stationIds` or `measurementIds` set.');
  }

  const { data } = await httpClient.get(`measurements`, {
    params: {
      stationIds: stationIds?.length ? stationIds.join(',') : undefined,
      measurementIds: measurementIds?.length ? measurementIds.join(',') : undefined,
    },
  });
  return data;
};
