import { type OidcStandardClaims } from 'oidc-client-ts';

export interface WithGeopraeventProfile extends OidcStandardClaims {
  geopraevent?: {
    language: string; // e.g. 'DE' | 'FR'
    customization: CustomTheme;
    hasAllStationsAccess: boolean;
    passwordChangeAllowed: boolean;
    phone: string;
    status: number;
    preferredTimezone?: string; // IANA time zone name
  };
  impersonator?: {
    username: string;
  };
}

export interface OidcModuleFactoryParams {
  authority: string;
  rootUrl: string;
  redirectPath: string;
  clientId: string;
  postLogoutRedirectUrl: string;
}

export enum CustomTheme {
  Geobrugg = 'GEOBRUGG',
  Geopraevent = 'GEOPRAEVENT',
  Geotest = 'GEOTEST',
  Geosat = 'GEOSAT',
  Hexagon = 'HEXAGON',
  Ids = 'IDS',
  Inauen = 'INAUEN',
  Skred = 'SKRED',
  Tas = 'TAS',
  Wyssen = 'WYSSEN',
}
