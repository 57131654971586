
  import { defineComponent } from 'vue';
  import { FormattedMessage } from '@/plugins/i18n/FormattedMessage';

  /**
   * @example [none]
   */
  export default defineComponent({
    name: 'ChunkLoadErrorMessage',
    components: {
      FormattedMessage,
    },
    props: {
      error: Error,
    },
    methods: {
      reload() {
        window.location.reload();
      },
    },
  });
