import { defineStore } from 'pinia';
import { keyBy } from 'lodash-es';
import * as Sentry from '@sentry/browser';

import {
  addFavouriteStation,
  getFavouriteStations,
  getStationInfo,
  getStations,
  removeFavouriteStation,
} from '@/api/station/station.api';

import { WebsocketConnectionStatus } from '@/api/utils/WebsocketClient';
import { StationStatus } from '@/api/station/types';
import {
  IAddFavouritesMutation,
  IAddStationInfoMutation,
  IAddStationsMutation,
  IIndexedStations,
  IMarkStationAsReadMutation,
  IRealtimeConnectionUpdate,
  IStation,
  IStationsState,
  IToggleStationAsFavourite,
  IUpdateStationsWithMessages,
  IRemoveFavouritePayload,
  IAddFavouritePayload,
} from './types';

export const useStationStore = defineStore('station', {
  state: (): IStationsState => ({
    stations: null,
    stationIds: [],
    favouriteIds: [],
  }),
  getters: {
    getDefaultViewId:
      (state) =>
      (stationId: string): string | null => {
        if (state.stations) {
          return state.stations[stationId].properties.defaultView;
        }
        return null;
      },
    hasStationInfo:
      (state) =>
      (stationId: string): boolean => {
        if (state.stations) {
          const stationInfo = state.stations[stationId];
          const hasViewsLoaded = !!stationInfo?.viewIds.length;
          return hasViewsLoaded;
        }
        return false;
      },
    getStationTimeZone:
      (state) =>
      (stationId: string): string | null => {
        if (state.stations) {
          return state.stations[stationId].properties.timeZone;
        }
        return null;
      },
    getRealtimeConnectionStatus:
      (state) =>
      (stationId: string): WebsocketConnectionStatus | null => {
        if (state.stations) {
          return state.stations[stationId]?.realtimeConnectionStatus || null;
        }

        return null;
      },
    defaultStation: (state): IStation | null => {
      if (!state.stationIds || !state.stations) {
        return null;
      }

      const indexedStations = state.stations;

      if (state.favouriteIds.length) {
        return indexedStations[state.favouriteIds[0]];
      }

      const firstActiveStationId = state.stationIds.find(
        (id) => indexedStations[id] && indexedStations[id].properties.modeOfOperation === StationStatus.Active,
      );

      if (firstActiveStationId) {
        return indexedStations[firstActiveStationId];
      }

      return indexedStations[state.stationIds[0]];
    },
    hasStations: (state): boolean => {
      return state.stationIds.length > 0;
    },
    stationsList: (state): IStation[] | null => {
      if (state.stationIds && state.stations) {
        return state.stationIds.map((id) => state.stations![id]);
      }

      return [];
    },
    stationsById: (state): IIndexedStations | null => {
      return state.stations;
    },
    hasStationUnreadMessages:
      (state) =>
      (stationId: string): boolean => {
        if (!state.stations) {
          return false;
        }
        return state.stations![stationId]?.properties.hasUnreadMessages || false;
      },
  },
  actions: {
    addStations({ stations }: IAddStationsMutation): void {
      if (stations) {
        if (!this.stations) {
          this.stations = {};
        }

        const ids: string[] = [];
        const stationsByIds: IIndexedStations = {};

        stations.forEach((station) => {
          const { id } = station;
          const {
            realtimeConnectionStatus,
            viewsById,
            viewIds,
            showStationInfo,
            showSms,
            showExport,
            showMessengerEditor,
          } = this.stations![id] || {};

          if (!ids.includes(id)) {
            ids.push(id);
          }

          stationsByIds[id] = {
            id,
            properties: Object.freeze(station), // avoid vue reactivity
            realtimeConnectionStatus: realtimeConnectionStatus || null,
            viewsById: viewsById || {},
            viewIds: viewIds || [],
            showStationInfo: showStationInfo || null,
            showSms: showSms || null,
            showExport: showExport || null,
            showMessengerEditor: showMessengerEditor || null,
          };
        });

        this.stationIds = ids;
        this.stations = stationsByIds;
      }
    },
    addStationInfo({ payload: { views, features, ...rest }, id }: IAddStationInfoMutation): void {
      if (!this.stations) {
        this.stations = {};
      }

      const viewIds = views.map((view) => view.id);
      const viewsById = keyBy(views, (view) => view.id);
      const { showStationInfo, showSms, showExport, showMessageEditor } = features;

      this.stations[id] = {
        ...this.stations[id],
        properties: Object.freeze(rest),
        viewsById: Object.freeze(viewsById),
        viewIds,
        showStationInfo,
        showSms,
        showExport: showExport ? true : false,
        showMessengerEditor: showMessageEditor ? true : false,
      };
    },
    setRealtimeConnectionStatus({ stationId, connectionState }: IRealtimeConnectionUpdate): void {
      if (!this.stations) {
        return;
      }

      const stationInfoToUpdate = this.stations[stationId];

      if (!stationInfoToUpdate) {
        return;
      }

      if (connectionState === null) {
        stationInfoToUpdate.realtimeConnectionStatus = null;
        return;
      }

      if (connectionState.reconnecting) {
        stationInfoToUpdate.realtimeConnectionStatus = WebsocketConnectionStatus.Connecting;
        return;
      }

      switch (connectionState.connected) {
        case true:
          stationInfoToUpdate.realtimeConnectionStatus = WebsocketConnectionStatus.Connected;
          break;
        case false:
          stationInfoToUpdate.realtimeConnectionStatus = WebsocketConnectionStatus.Disconnected;
          break;
        default:
      }
    },
    async markStationAsRead({ stationId }: IMarkStationAsReadMutation) {
      if (!this.stations) {
        return;
      }

      const station = this.stations[stationId];

      if (station) {
        this.stations[stationId] = {
          ...station,
          properties: Object.freeze({
            ...station.properties,
            hasUnreadMessages: false,
          }),
        };
      }
    },
    addFavourites({ favourites }: IAddFavouritesMutation) {
      const favouriteIds: string[] = [];

      favourites.forEach((id) => {
        favouriteIds.push(id);
      });

      this.favouriteIds = favouriteIds;
    },
    toggleStationAsFavourite({ stationId, isFavourite }: IToggleStationAsFavourite) {
      const { favouriteIds } = this;
      const indexOfFavourite = favouriteIds.indexOf(stationId);

      if (isFavourite && indexOfFavourite === -1) {
        favouriteIds.push(stationId);
        return;
      }

      if (isFavourite && indexOfFavourite > -1) {
        return;
      }

      favouriteIds.splice(indexOfFavourite, 1);
    },
    updateStationsWithMessages({ stations }: IUpdateStationsWithMessages) {
      const stateStations = this.stations;

      if (!stations || !stateStations) {
        return;
      }

      stations.forEach((station) => {
        const { id, hasUnreadMessages } = station;
        const { properties } = stateStations[id] || {};

        if (properties && properties.hasUnreadMessages !== hasUnreadMessages) {
          stateStations[id].properties = Object.freeze({
            ...properties,
            hasUnreadMessages,
          });
        }
      });
    },
    async fetchStations(): Promise<void> {
      const response = await getStations();
      this.addStations({ stations: response.stations } as IAddStationsMutation);
    },
    async fetchStationInfo(stationId: string): Promise<void> {
      const stationInfo = await getStationInfo(stationId);
      this.addStationInfo({
        id: stationId,
        payload: stationInfo,
      } as IAddStationInfoMutation);
    },
    async updateRealtimeConnectionStatus(payload: IRealtimeConnectionUpdate): Promise<void> {
      this.setRealtimeConnectionStatus(payload);
    },
    async fetchFavourites(): Promise<void> {
      const response = await getFavouriteStations();
      this.addFavourites({ favourites: response.stations } as IAddFavouritesMutation);
    },
    async addFavourite({ stationId }: IAddFavouritePayload): Promise<void> {
      await addFavouriteStation(stationId);
      this.toggleStationAsFavourite({ stationId, isFavourite: true } as IToggleStationAsFavourite);
    },
    async removeFavourite({ stationId }: IRemoveFavouritePayload): Promise<void> {
      await removeFavouriteStation(stationId);
      this.toggleStationAsFavourite({ stationId, isFavourite: false } as IToggleStationAsFavourite);
    },
    async checkForNewMessages(): Promise<void> {
      try {
        const response = await getStations();
        this.updateStationsWithMessages({ stations: response.stations } as IUpdateStationsWithMessages);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  },
});
