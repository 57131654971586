
  import { defineComponent } from 'vue';
  import { mdiContentPaste, mdiImageMultiple, mdiPoll, mdiVideoImage } from '@mdi/js';

  import { SupportedViewKinds } from '@/api/station/types';

  export default defineComponent({
    name: 'Tile',
    props: {
      kind: String,
      elevation: {
        type: String,
        default: '1',
      },
      rounded: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      iconSize() {
        return this.$vuetify.display.mdAndUp ? 'default' : 'small';
      },
      kindIcon(): string {
        switch (this.kind) {
          case SupportedViewKinds.Chart:
            return mdiPoll;
          case SupportedViewKinds.ImageViewer:
            return mdiImageMultiple;
          case SupportedViewKinds.LiveImage:
            return mdiVideoImage;
          case SupportedViewKinds.Html:
            return mdiContentPaste;
          default:
            return '';
        }
      },
    },
  });
