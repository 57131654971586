import { defineStore } from 'pinia';
import { PlaceOrderPayload, RawDataExportState } from './types';
import {
  getMeasurements,
  getRawDataExportOrders,
  placeRawDataExportOrder,
  RawDataExportOrderProgress,
} from '@/api/station';
import { useRootStore } from '@/store';
import UserFriendlyError from '@/common/utils/UserFriendlyError';

export const useRawDataExportStore = defineStore('rawDataExport', {
  state: (): RawDataExportState => ({ orders: [], measurements: [] }),
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getMeasurements(state) {
      return state.measurements;
    },
  },
  actions: {
    async placeOrder({ stationId, measurementIds, from, to }: PlaceOrderPayload): Promise<void> {
      const rootStore = useRootStore();
      const timestamp = new Date();
      let exportFrom = from;
      let exportTo = to;

      // trim dates to "now" at max
      if (exportFrom > timestamp) {
        exportFrom = timestamp;
      }
      if (exportTo > timestamp) {
        exportTo = timestamp;
      }

      try {
        await placeRawDataExportOrder(
          stationId,
          timestamp.toISOString(),
          measurementIds,
          exportFrom.toISOString(),
          exportTo.toISOString(),
        );
        this.orders = [
          {
            timestamp,
            progress: RawDataExportOrderProgress.Pending,
            measurements: this.measurements.filter((m) => measurementIds.find((id) => id === m.id)),
            exportFrom,
            exportTo,
          },
          ...this.orders,
        ];
      } catch (e) {
        rootStore.setError({ error: new UserFriendlyError('RawDataExport.Errors.FailedToSubmit'), intrusive: false });
      }
    },
    async fetchOrders(stationId: string): Promise<void> {
      const rootStore = useRootStore();

      try {
        const response = await getRawDataExportOrders(stationId);
        this.orders = response.sort((a, b) => (new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1));
      } catch (e) {
        rootStore.setError({ error: new UserFriendlyError('RawDataExport.Errors.FailedToFetch'), intrusive: false });
      }
    },
    async fetchMeasurements(stationId: string): Promise<void> {
      const rootStore = useRootStore();

      try {
        const response = await getMeasurements([stationId]);
        this.measurements = response.measurements;
      } catch (e) {
        rootStore.setError({ error: new UserFriendlyError('RawDataExport.Errors.FailedToFetch'), intrusive: false });
      }
    },
  },
});
