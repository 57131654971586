import { utcToZonedTime, format as dateFormat, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { enUS, zhCN, es, nb, fr, de, it } from 'date-fns/locale';
import {
  isValid,
  parse,
  parseISO,
  formatDistanceStrict as dateFormatDistanceStrict,
  formatDuration as dateFormatDuration,
} from 'date-fns';
import { useRootStore } from '@/store';

const localeMap = new Map<string, Locale>([
  ['de', de],
  ['fr', fr],
  ['cn', zhCN],
  ['en', enUS],
  ['es', es],
  ['it', it],
  ['no', nb],
]);

export interface ICalculateFunction {
  (argument: Date): Date;
}

export const getDateLocale = (locale: string | null): Locale => {
  return locale ? localeMap.get(locale) || enUS : enUS;
};

/**
 * Format a given Date to a given format in the user's locale, without time zone adjustments
 *
 * @param utcTimestamp
 * @param formatStr
 */
export const format = (utcTimestamp: Date, formatStr: string = 'Pp'): string => {
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;
  return dateFormat(utcTimestamp, formatStr, { locale: getDateLocale(userLanguage) });
};

/**
 * Format a Date, ISO-String or number of milliseconds since unix epoch to the given format
 * in the user's locale in the given time zone OR the preferred time zone as configured for the user.
 * Falls back to UTC if no time zone is specified.
 *
 * @param utcDate
 * @param timeZone
 * @param displayFormat
 */
export function formatToTimezone(
  utcDate: number | string | Date,
  timeZone?: string | null,
  displayFormat: string = 'Pp',
): string {
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;
  const preferredTimezone = rootStore.preferredTimezone;
  const utcFallbackTimezone = 'UTC';
  return formatInTimeZone(utcDate, preferredTimezone || timeZone || utcFallbackTimezone, displayFormat, {
    locale: getDateLocale(userLanguage),
  });
}

/**
 * Format a Date, ISO-String or number of milliseconds since unix epoch to the given format
 * in the user's locale in the given time zone without considering the preferred time zone as configured for the user.
 *
 * @param utcDate
 * @param timeZone
 * @param displayFormat
 */
export function formatToTimezoneStrict(
  utcDate: number | string | Date,
  timeZone: string,
  displayFormat: string = 'Pp',
): string {
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;
  return formatInTimeZone(utcDate, timeZone, displayFormat, {
    locale: getDateLocale(userLanguage),
  });
}

/**
 * Calculates a UTC Date from a partial ISO String.
 * Partial ISO strings are considered to be in station time.
 *
 * e.g.: 2020-07, 2020-06-05
 *
 * Do not use this function with full ISO strings!
 *
 * @param partialISOString part of an ISO string, which is considered to be in station time
 * @param timeZone the timezone of the station
 * @param calculateFunction optionally a calculate function to run on the station date (e.g. startOfDay)
 */
export function calculateUtcForStationTimeFromPartialISO(
  partialISOString: string,
  timeZone: string,
  calculateFunction: ICalculateFunction,
) {
  const dateFromPartialISOString = parseISO(partialISOString); // resulting date is a local (user's browser) date, due to missing timezone identifier
  const currentStationDate = getCurrentStationDate(timeZone);

  if (partialISOString.length >= 24) {
    throw 'Full ISO strings not supported by this function';
  }

  // parseISO considers a partial string given to be in the user's timezone, missing values will be filled with the user's timezone time at midnight
  // thus, if time is missing, we reset hours, minutes and seconds here to the time at station's timezone (user inputs are always considered to be station timezone)
  // also see: https://github.com/date-fns/date-fns/issues/1838#issuecomment-696646788
  if (partialISOString.length < 11) {
    dateFromPartialISOString.setHours(
      currentStationDate.getHours(),
      currentStationDate.getMinutes(),
      currentStationDate.getSeconds(),
    );
  }

  if (partialISOString.length <= 7) {
    // when partialISOString with yearn and months only (e.g. '2020-06'), we supply the 2nd day of the month to get the right result for the upcoming math (the time won't matter then)
    dateFromPartialISOString.setDate(2);
  }

  // as the parsed date is assumed to be a date in station timezone,
  // we can directly apply the calculation to it
  const calculatedDateInStationTime = calculateFunction(dateFromPartialISOString);

  return zonedTimeToUtc(calculatedDateInStationTime, timeZone);
}

/**
 * Parses a locale based date string (usually entered by the user) into a Date. Returns Null if string couldn't be parsed into a valid Date.
 * @param value the string entered by the user in the locale date format of his language ('P' format token in date-fns). It is considered as station timezone
 * @param timeZone the IANA time zone of the station
 * @param locale the locale of the user as a string
 * @param calculateFn optionally a function to run on the station's date: like startOfDay
 */
export function calculateUtcForStationTimeFromString(
  value: string,
  timeZone: string,
  locale: string | null = 'en',
  calculateFn: (date: Date) => Date = (date) => date,
): Date | null {
  const currentStationDate = getCurrentStationDate(timeZone);
  const parsedDate = parse(value, 'P', currentStationDate, {
    locale: getDateLocale(locale), // only with a locale the specific separator tokens of a specific language can be parsed correctly
  });

  if (isNaN(parsedDate.valueOf())) {
    return null;
  }

  // as pointed out in GitHub: https://github.com/date-fns/date-fns/issues/1838#issuecomment-696646788
  // parse() creates a date in the user's timezone at midnight to fill in missing values on the string,
  // and not as pointed out in the docs for 2.17.0 from the reference date.
  // thus, we set the actual hours here again.
  parsedDate.setHours(currentStationDate.getHours(), currentStationDate.getMinutes(), currentStationDate.getSeconds());

  // once the string has been parsed into a station date (!),
  // we run the desired calculate function on it, then transform it to UTC
  return zonedTimeToUtc(calculateFn(parsedDate), timeZone);
}

/**
 * Applies a given calculation to a full ISO String (incl. timezone identifier) or a Date,
 * assuming that it represents UTC date and time.
 *
 * Returns a Date representing UTC
 *
 */
export function calculateUtcFromDateOrFullISO(
  stringOrDate: string | Date,
  timeZone: string,
  calculationFn: (date: Date) => Date = (date) => date,
) {
  let parsedDate: Date;

  if (typeof stringOrDate === 'string') {
    if (stringOrDate.length < 24) {
      throw 'Partial ISO strings not supported by this function';
    }

    parsedDate = parseISO(stringOrDate);
  } else {
    parsedDate = stringOrDate as Date;
  }

  // Convert it to the station timezone first,
  // so that the applied calculation function acts on the station's time, not UTC
  const calculatedDate = utcToZonedTime(parsedDate, timeZone);

  // then apply the fn and convert back to UTC
  return zonedTimeToUtc(calculationFn(calculatedDate), timeZone);
}

export function isValidTime(input: string, format = 'HH:mm', referenceDate = new Date()) {
  return isValid(parse(input, format, referenceDate));
}

export function formatToMonth(isoString: string) {
  const isoDate = parseISO(isoString);
  return format(isoDate, 'LLL yyyy');
}

export function formatToDay(isoString: string) {
  const isoDate = parseISO(isoString);
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;

  return dateFormat(isoDate, 'P', { locale: getDateLocale(userLanguage) });
}

export function getCurrentStationDate(timeZone: string): Date {
  const currentUtcDate = new Date(new Date().toUTCString());

  return utcToZonedTime(currentUtcDate, timeZone);
}

/**
 * Returns the offset in milliseconds from the user's timezone to the station's timezone
 * Calculates the offset using current station date and current user date
 */
export function getUserTZToStationTZOffset(stationTimeZone: string): number {
  return getCurrentStationDate(stationTimeZone).valueOf() - new Date().valueOf();
}

export function formatDistanceStrict(
  date: Date | number,
  baseDate: Date | number,
  options?: Parameters<typeof dateFormatDistanceStrict>[2],
): string {
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;
  return dateFormatDistanceStrict(date, baseDate, { ...options, locale: getDateLocale(userLanguage) });
}

export type FormatDurationOptions = Parameters<typeof dateFormatDuration>[1] & { short?: boolean };

/**
 *
 * @param duration A date-fns duration object (use intervalToDuration() to obtain it)
 * @param options A date-fns options object. Additionally, you can pass a property 'short: boolean' (default: false) to return a special short format for hours, minutes and seconds: 2h 1min 30s
 */
export function formatDuration(duration: Duration, options?: FormatDurationOptions): string {
  const rootStore = useRootStore();
  const userLanguage = rootStore.locale;
  const formatDistanceMap: { [key: string]: string } = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}min',
    xHours: '{{count}}h',
  };
  const shortLocale = {
    formatDistance: (token: string, count: string) => formatDistanceMap[token].replace('{{count}}', count),
  };
  const localeToUse = options?.short ? shortLocale : getDateLocale(userLanguage);
  return dateFormatDuration(duration, { locale: localeToUse, ...options });
}

export function getCurrentUTCDate(): Date {
  return new Date(new Date().toUTCString());
}
