
  import { defineComponent } from 'vue';
  import { mapState } from 'pinia';

  import ErrorTemplate from '@/common/components/Error/ErrorTemplate.vue';
  import { useRootStore } from '@/store';

  export default defineComponent({
    components: {
      ErrorTemplate,
    },
    computed: {
      ...mapState(useRootStore, ['error']),
      extendedError(): Error {
        return this.error || new Error();
      },
    },
  });
