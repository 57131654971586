import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60f157be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-wrapper mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_PartnerLogo = _resolveComponent("PartnerLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_NavigationUserInfo = _resolveComponent("NavigationUserInfo")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    "model-value": _ctx.drawer,
    width: _ctx.drawerWidth,
    "onUpdate:modelValue": _ctx.toggleDrawer,
    color: "white",
    class: "navigation-drawer",
    "disable-route-watcher": "",
    order: "-1"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: "/",
          exact: "",
          class: "d-flex flex-column align-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Logo, { class: "mt-2 mb-4 logo" }),
            (_ctx.customization)
              ? (_openBlock(), _createBlock(_component_PartnerLogo, {
                  key: 0,
                  kind: _ctx.customization,
                  class: "partner-logo"
                }, null, 8, ["kind"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_v_divider),
        _createVNode(_component_NavigationUserInfo, {
          user: _ctx.user,
          login: _ctx.login,
          logout: _ctx.logout,
          impersonator: _ctx.impersonator,
          class: "mb-n2 mt-n2"
        }, null, 8, ["user", "login", "logout", "impersonator"]),
        _createVNode(_component_v_divider, { class: "mb-1" }),
        (_ctx.user)
          ? (_openBlock(), _createBlock(_KeepAlive, {
              key: 0,
              include: _ctx.stationsNavigationComponentName
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.navToShow), {
                onForceShowStations: _cache[0] || (_cache[0] = ($event: any) => (_ctx.forceShowStations = $event)),
                isOrphan: _ctx.isOrphanStation,
                "enable-station-favourites": _ctx.enableStationFavourites,
                onNavigate: _ctx.onNavigate
              }, null, 40, ["isOrphan", "enable-station-favourites", "onNavigate"]))
            ], 1032, ["include"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["model-value", "width", "onUpdate:modelValue"]))
}