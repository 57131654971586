import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-253ac0fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-block pl-6",
  style: {"height":"14px"}
}
const _hoisted_2 = { class: "d-flex justify-end pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RawDataExportMeasurementChooser = _resolveComponent("RawDataExportMeasurementChooser")!
  const _component_v_messages = _resolveComponent("v-messages")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_DateRangeSelector = _resolveComponent("DateRangeSelector")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            "justify-space-between": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_RawDataExportMeasurementChooser, {
                    stationId: _ctx.stationId,
                    measurements: _ctx.getMeasurements,
                    onSelectionChanged: _ctx.onMeasurementIdsChange
                  }, null, 8, ["stationId", "measurements", "onSelectionChanged"]),
                  _createElementVNode("span", _hoisted_1, [
                    _createVNode(_component_v_messages, {
                      active: !_ctx.measurementIdsValid,
                      color: "error",
                      messages: String(_ctx.$t('RawDataExport.Validation.MeasurementSelection')),
                      transition: "false"
                    }, null, 8, ["active", "messages"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  (!!_ctx.timeZone && !!_ctx.locale)
                    ? (_openBlock(), _createBlock(_component_DateRangeSelector, {
                        key: 0,
                        class: "mx-4",
                        "time-zone": _ctx.timeZone,
                        onChange: _ctx.onDateRangeChange,
                        locale: _ctx.locale,
                        from: new Date(new Date().setDate(new Date().getDate() - 7)),
                        to: new Date(),
                        onFormValidChanged: _ctx.onDateRangeValidChanged
                      }, null, 8, ["time-zone", "onChange", "locale", "from", "to", "onFormValidChanged"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_btn, {
                      class: "mr-4",
                      onclick: _ctx.onClose
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('RawDataExport.Actions.Abort')), 1)
                      ]),
                      _: 1
                    }, 8, ["onclick"]),
                    _createVNode(_component_v_btn, {
                      type: "submit",
                      disabled: !_ctx.isFormValid,
                      color: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('RawDataExport.Actions.ScheduleExport')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}