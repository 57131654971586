
  import { defineComponent } from 'vue';
  import { CustomTheme } from '@/pages/Oidc/store/types';

  import GeobruggLogo from './logos/geobrugg.svg';
  import GeosatLogo from './logos/geosat.png';
  import GeotestLogo from './logos/geotest.png';
  import HexagonLogo from './logos/hexagon.png';
  import IdsLogo from './logos/ids.png';
  import InauenLogo from './logos/inauen.png';
  import SkredLogo from './logos/skred.png';
  import TasLogo from './logos/tas.png';
  import WyssenLogo from './logos/wyssen.png';

  export default defineComponent({
    name: 'PartnerLogo',
    props: {
      kind: {}, // compatibility with Enum
    },
    computed: {
      altText() {
        return `${this.kind} logo`;
      },
      imgSrc() {
        switch (this.kind) {
          case CustomTheme.Geobrugg:
            return GeobruggLogo;
          case CustomTheme.Geosat:
            return GeosatLogo;
          case CustomTheme.Geotest:
            return GeotestLogo;
          case CustomTheme.Hexagon:
            return HexagonLogo;
          case CustomTheme.Ids:
            return IdsLogo;
          case CustomTheme.Inauen:
            return InauenLogo;
          case CustomTheme.Skred:
            return SkredLogo;
          case CustomTheme.Tas:
            return TasLogo;
          case CustomTheme.Wyssen:
            return WyssenLogo;
          default:
            return null;
        }
      },
    },
  });
