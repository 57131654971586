import ChunkLoadError from '@/common/utils/ChunkLoadError';
import { useRootStore } from '@/store';

/**
 * Wrapper around webpack's async import() function that will catch modules that fail to resolve
 * and display a nice error message
 */
export function asyncImportHandler(module: Promise<any>) {
  return module.catch((e) => {
    const rootStore = useRootStore();

    rootStore.setError({
      error: new ChunkLoadError(e),
    });

    return {
      __esModule: true,
      default: null,
    };
  });
}
