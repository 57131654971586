
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';
  import {
    mdiChevronLeft,
    mdiCloseThick,
    mdiCloudDownloadOutline,
    mdiEmailOutline,
    mdiFormatListBulleted,
    mdiRadioTower,
    mdiInformationOutline,
    mdiMessageTextOutline,
    mdiPause,
    mdiShieldLinkVariant,
    mdiStar,
    mdiStarOutline,
  } from '@mdi/js';
  import { GlobalEvents } from 'vue-global-events';

  import { UserLevel } from '@/store/types';
  import { RouteNames } from '@/router/types';
  import PanelListItem from '@/pages/Navigation/components/PanelListItem/PanelListItem.vue';
  import ViewListItem from '@/pages/Navigation/components/ViewListItem/ViewListItem.vue';
  import { StationStatus } from '@/api/station/types';
  import KeyboardShortcut from '@/common/components/KeyboardShortcut/KeyboardShortcut.vue';
  import { excludeFormElements } from '@/common/utils/globalEventFilters';
  import { IIndexedStations } from '@/areas/Station/store/types';
  import { useStationStore } from '@/areas/Station/store';
  import { useRootStore } from '@/store';

  export default defineComponent({
    name: 'ViewNavigation',
    components: { KeyboardShortcut, ViewListItem, PanelListItem, GlobalEvents },
    props: {
      isOrphan: Boolean,
      enableStationFavourites: Boolean,
    },
    emits: ['forceShowStations', 'navigate'],
    computed: {
      mdiChevronLeftIcon() {
        return mdiChevronLeft;
      },
      mdiStationIcon() {
        return mdiRadioTower;
      },
      mdiStarIcon() {
        return mdiStar;
      },
      mdiStarOutlineIcon() {
        return mdiStarOutline;
      },
      panels() {
        const station = this.activeStation;

        if (!station) {
          return [];
        }

        const {
          showSms,
          showStationInfo,
          showExport,
          showMessengerEditor,
          properties: { hasUnreadMessages },
          id,
        } = station;
        const t = this.$t.bind(this);

        return [
          {
            name: t('App.PanelAdminLabel') as string,
            icon: mdiShieldLinkVariant,
            isVisible: this.isAdmin,
            href: this.adminStationUrl,
          },
          {
            name: t('App.PanelMessagesLabel') as string,
            icon: mdiMessageTextOutline,
            hasUnreadMessages,
            isVisible: true,
            to: { name: RouteNames.StationMessages, params: { stationId: id } },
          },
          {
            name: t('App.PanelInfoLabel') as string,
            icon: mdiInformationOutline,
            isVisible: showStationInfo,
            to: { name: RouteNames.StationInfo, params: { stationId: id } },
          },
          {
            name: t('App.PanelMessengerEditorLabel') as string,
            icon: mdiFormatListBulleted,
            isVisible: showMessengerEditor,
            to: { name: RouteNames.MessengerListEditor, params: { stationId: id } },
          },
          {
            name: t('App.PanelSmsLabel') as string,
            icon: mdiEmailOutline,
            isVisible: showSms,
            to: { name: RouteNames.StationSms, params: { stationId: id } },
          },
          {
            name: t('App.PanelExportLabel') as string,
            icon: mdiCloudDownloadOutline,
            isVisible: showExport,
            to: { name: RouteNames.Export, params: { stationId: id } },
          },
        ];
      },
      viewList() {
        const station = this.activeStation;

        if (!station) {
          return [];
        }

        return station.viewIds.map((id) => station.viewsById[id]);
      },
      activeStationId() {
        return this.$route.params.stationId as string;
      },
      activeStation() {
        return this.stationsById ? this.stationsById[this.activeStationId] : null;
      },
      isAdmin() {
        return this.userLevel === UserLevel.Admin;
      },
      adminStationUrl() {
        return `${runtimeAppSettings.adminUiBaseUrl}/stations/${this.activeStationId}/show`;
      },
      isFavourite() {
        return this.favouriteIds.includes(this.activeStationId);
      },
      deEmphasizedStationIcon() {
        switch (this.activeStation?.properties.modeOfOperation) {
          case StationStatus.Standby:
            return mdiPause;
          case StationStatus.Inactive:
            return mdiCloseThick;
          default:
            return null;
        }
      },
      ...(mapState(useStationStore, ['stationsById', 'favouriteIds']) as {
        stationsById(): IIndexedStations | null;
        favouriteIds(): string[];
      }),
      ...mapState(useRootStore, ['userLevel']),
    },
    methods: {
      emitForceShowStations() {
        this.$emit('forceShowStations', true);
      },
      async toggleFavourite() {
        if (this.isFavourite) {
          await this.removeFavourite({ stationId: this.activeStationId });
        } else {
          await this.addFavourite({ stationId: this.activeStationId });
        }
      },
      emitNavigate() {
        this.$emit('navigate');
      },
      excludeFilter(e: KeyboardEvent) {
        return excludeFormElements(e);
      },
      ...mapActions(useStationStore, ['addFavourite', 'removeFavourite']),
    },
  });
