
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';

  import ErrorMessage from '@/common/components/ErrorMessage/ErrorMessage.vue';
  import CustomTransition from '@/common/components/CustomTransition/CustomTransition.vue';
  import ReturnToMobileApp from '@/common/components/ReturnToMobileApp/ReturnToMobileApp.vue';
  import FullHeight from '@/common/components/FullHeight/FullHeight.vue';
  import { useRootStore } from '@/store';

  export default defineComponent({
    name: 'AppStandalone',
    components: {
      ErrorMessage,
      CustomTransition,
      ReturnToMobileApp,
      FullHeight,
    },
    methods: {
      ...mapActions(useRootStore, ['setError']),
    },
    computed: {
      ...mapState(useRootStore, ['error', 'errorIsIntrusive']),
    },
  });
