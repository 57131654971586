import { defineAsyncComponent, type AsyncComponentLoader } from 'vue';

import ChunkLoadError from '@/common/utils/ChunkLoadError';
import { useRootStore } from '@/store';

export function asyncComponentFactory(componentLoader: AsyncComponentLoader): ReturnType<typeof defineAsyncComponent> {
  return defineAsyncComponent({
    loader: componentLoader,
    onError(error) {
      const rootStore = useRootStore();
      // loading chunk failed. ex. due to stale html file after new deployment.
      // display an error asking the user to refresh the page
      rootStore.setError({
        error: new ChunkLoadError(error),
      });
    },
  });
}
