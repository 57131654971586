
  import { defineComponent, type PropType } from 'vue';
  import { type RouteLocation } from 'vue-router';

  export default defineComponent({
    name: 'PanelListItem',
    props: {
      isVisible: Boolean,
      to: Object as PropType<RouteLocation>,
      href: String,
      target: String,
      icon: String,
      hasUnreadMessages: Boolean,
      name: String,
    },
  });
