
  import { defineComponent } from 'vue';

  import Announcement from '@/common/components/Announcement/Announcement.vue';
  import HttpError from '@/api/utils/HttpError';
  import { HttpErrorKind } from '@/api/utils/HttpErrorEnums';
  import UserFriendlyError from '@/common/utils/UserFriendlyError';
  import { mdiEmoticonNeutralOutline } from '@mdi/js';

  export default defineComponent({
    components: {
      Announcement,
    },
    props: {
      error: Error,
    },
    computed: {
      mdiEmoticonNeutralIcon() {
        return mdiEmoticonNeutralOutline;
      },
      homeLink() {
        return runtimeAppSettings.rootUrl;
      },
      statusTitle(): string {
        switch (true) {
          case this.error instanceof HttpError: {
            const error = this.error as HttpError;
            return `${this.$t('Error.TitleForCode', {
              code: error.status,
              meaning: HttpErrorKind[error.status],
            })}`;
          }
          default:
            return `${this.$t('Error.GenericErrorMessageTitle')}`;
        }
      },
      statusText(): string {
        switch (true) {
          case this.error instanceof HttpError: {
            const error = this.error as HttpError;
            return `${this.$t('Error.TextForCode', { code: error.status })}`;
          }
          case this.error instanceof UserFriendlyError: {
            const error = this.error as UserFriendlyError;
            return `${this.$t(error.userFriendlyMessage)}`;
          }
          default:
            return `${this.$t('Error.GenericErrorMessageText')}`;
        }
      },
    },
  });
