import { RouteLocationNormalized } from 'vue-router';
import { isEqual } from 'lodash-es';

import { useViewStore } from '@/areas/GenericView/store';

export async function fetchDataRouteGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
  const isParamsEqual = isEqual(to.params, from.params);
  const viewStore = useViewStore();

  // avoid to re-fetch view data if only query changed,
  // to prevent lagging when zooming in maps (maps update url with x/y coordinates)
  if (to.name === from.name && isParamsEqual) {
    next();
    return;
  }

  await viewStore.fetchData({
    viewId: to.params.viewId as string,
    stationId: to.params.stationId as string,
  });

  next();
}
