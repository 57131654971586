import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_Announcement = _resolveComponent("Announcement")!

  return (_openBlock(), _createBlock(_component_Announcement, null, {
    title: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass({ 'text-h4': _ctx.$vuetify.display.xs, 'text-h3': _ctx.$vuetify.display.smAndUp })
      }, _toDisplayString(_ctx.statusTitle), 3)
    ]),
    illustration: _withCtx(() => [
      _createVNode(_component_v_icon, { size: "x-large" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.mdiEmoticonNeutralIcon), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.statusText), 1),
      _createElementVNode("a", {
        href: _ctx.homeLink,
        alt: "home"
      }, _toDisplayString(_ctx.$t('Error.BackToHomeLink')), 9, _hoisted_1)
    ]),
    _: 1
  }))
}