import { AxiosRequestConfig } from 'axios';

export default class HttpError extends Error {
  request: Partial<AxiosRequestConfig> | null = null;

  constructor(
    public status: number,
    public message: string,
    private requestConfig?: AxiosRequestConfig,
  ) {
    super(message);

    this.status = status;

    if (requestConfig) {
      this.request = this.filterRequestConfig(requestConfig);
    }

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  filterRequestConfig(requestConfig: AxiosRequestConfig): Partial<AxiosRequestConfig> {
    const propertiesToInclude = ['url', 'method', 'headers', 'baseURL', 'timeout', 'data'];

    const filteredConfig = Object.keys(requestConfig)
      .filter((key) => propertiesToInclude.includes(key))
      .reduce((obj, prop) => {
        (obj as any)[prop] = (requestConfig as any)[prop];
        return obj;
      }, {} as Partial<AxiosRequestConfig>);

    return filteredConfig;
  }
}
