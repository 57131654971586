import { createApp, h } from 'vue';
import { createVuetify } from 'vuetify';
import * as Sentry from '@sentry/vue';
import { CaptureConsole } from '@sentry/integrations';
import { RouteLocationNormalizedLoaded, RouterView } from 'vue-router';
import { createPinia, mapActions, mapState } from 'pinia';

import { createIntl, locale } from '@/plugins/i18n';
import { setConfiguredLocale } from '@/plugins/i18n/utils';
import { intlKeyBridge } from '@/plugins/i18n/intlKeyBridge';

import { useRootStore } from './store';
import router from './router'; // router instantiated with createRouter()
import { customTheme } from './plugins/vuetify';

import 'vuetify/styles';

const isProduction = process.env.NODE_ENV === 'production';

async function initApp() {
  const intl = await createIntl();
  const vuetify = createVuetify(customTheme);
  const pinia = createPinia();

  const app = createApp({
    name: 'GpMain',
    watch: {
      locale: {
        immediate: true,
        handler(value, oldValue) {
          if (value && oldValue && value !== oldValue) {
            setConfiguredLocale(value);
          }
        },
      },
    },
    methods: {
      ...mapActions(useRootStore, ['updateLocale']),
    },
    computed: {
      ...mapState(useRootStore, ['locale']),
    },
    created() {
      this.updateLocale(locale);
    },
    render: () => h(RouterView),
  });

  // register router's currentRoute property as a custom pinia property,
  // so that it can be used in store actions and getters
  pinia.use(({ store }) => {
    // @ts-expect-error currentRoute is a Ref, but pinia automatically unwraps refs in store, that's why we ignore the typescript error
    store.route = router.currentRoute as RouteLocationNormalizedLoaded;
  });

  app.use(router);
  app.use(vuetify);
  app.use(intl);
  app.use(intlKeyBridge); // helper plugin to transform former vue-i18n's '$t()' into vue-intl's '$formatMessage()' syntax
  app.use(pinia);

  Sentry.init({
    app,
    dsn: runtimeAppSettings.sentryDsn,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.NODE_ENV,
    autoSessionTracking: false,
    integrations: !isProduction ? [new CaptureConsole()] : undefined,
  });

  app.mount('#app');
}

initApp();

// eslint-disable-next-line no-console
console.info(`%cWelcome to Geopraevent: Running ${process.env.VUE_APP_VERSION}`, 'font-weight:bold;color:#5d7188;');
