import { RouteRecordRaw } from 'vue-router';
import { defineComponent, h } from 'vue';

import Oidc from '@/pages/Oidc';
import Root from '@/pages/Root';
import Passthrough from '@/pages/Passthrough/Passthrough.vue';
import { asyncRouteGuard } from '@/router/utils/async-route-guard';
import GenericView from '@/areas/GenericView/views/GenericView/GenericView';
import ErrorBoundary from '@/common/components/Error/ErrorBoundary.vue';
import Four04 from '@/pages/Error/views/404.vue';
import GenericError from '@/pages/Error/views/GenericError.vue';
import { fetchDataRouteGuard } from '@/areas/GenericView/router/utils';
import OidcLogout from '@/pages/Oidc/views/OidcLogout.vue';
import DocumentTitle from '@/common/components/DocumentTitle/DocumentTitle.vue';
import { asyncComponentFactory } from '@/router/utils/async-component-factory';
import { RouteNames } from '@/router/types';
import {
  loadStationsAndFavouritesRouteGuard,
  stationRouteGuard,
  stationsRouteGuard,
} from '@/areas/Station/utils/guards';
import { stationMessagesRouteGuard } from '@/areas/StationMessages/utils/guards';
import { marketingAppTitle } from '@/common/constants';
import SilentLogout from '@/pages/Oidc/views/SilentLogout.vue';
import type { default as IStationMessages } from '@/areas/StationMessages/views/StationMessages.vue';
import { useRootStore } from '@/store';
import { RawDataExportView } from '@/areas/RawDataExport';

// Async vue-router components
// Note that router's onError handler will deal with components failing to resolve
const LegacyViews = () => import('@/areas/LegacyViews/views/LegacyViews.vue');
const StationMessages = () => import('@/areas/StationMessages/views/StationMessages.vue');
const Support = () => import('@/pages/Support/Support.vue');

// Async vue components
const ImageViewer = asyncComponentFactory(() => import('@/areas/ImageViewer/views/ImageViewer/ImageViewer.vue'));
const MessengerLists = asyncComponentFactory(() => import('@/areas/MessengerLists/views/MessengerLists.vue'));

const routes: RouteRecordRaw[] = [
  {
    path: '/silent-logout',
    name: RouteNames.SilentLogout,
    component: SilentLogout,
    meta: {
      isPublic: true,
    },
  },
  {
    // Needs to be top-level to support `standalone` mode.
    // Note that since vue-router@4, all paths have a trailing slash by default.
    path: '/',
    component: Root,
    children: [
      {
        path: '',
        name: RouteNames.Home,
        redirect: { name: RouteNames.Stations },
        meta: {
          isPublic: true,
        },
      },
      {
        path: '/support',
        name: RouteNames.Support,
        beforeEnter: asyncRouteGuard(loadStationsAndFavouritesRouteGuard),
        component: Support,
        meta: {
          isPublic: true,
        },
      },
      {
        path: runtimeAppSettings.oidcRedirectPath,
        name: RouteNames.Oidc,
        component: Oidc,
        meta: {
          isOidcCallback: true,
        },
      },
      {
        path: '/logout',
        name: RouteNames.Logout,
        component: OidcLogout,
        meta: {
          isPublic: true,
        },
      },
      {
        // Ensure compatibility with bookmarks from users that point to the old route
        path: '/station/:stationId/view/:viewId',
        redirect: { name: RouteNames.View },
      },
      {
        path: '/profile',
        name: RouteNames.Profile,
        beforeEnter: asyncRouteGuard(loadStationsAndFavouritesRouteGuard),
        component: LegacyViews,
        props: () => ({ kind: RouteNames.Profile }),
      },
      {
        path: '/stations',
        name: RouteNames.Stations,
        beforeEnter: asyncRouteGuard(stationsRouteGuard),
        component: defineComponent({
          beforeRouteUpdate: asyncRouteGuard(stationsRouteGuard),
          render() {
            return h(Passthrough);
          },
        }),
        children: [
          {
            path: ':stationId',
            name: RouteNames.Station,
            beforeEnter: asyncRouteGuard(stationRouteGuard),
            component: defineComponent({
              beforeRouteUpdate: asyncRouteGuard(stationRouteGuard),
              render() {
                return h(Passthrough);
              },
            }),
            children: [
              {
                path: 'messages',
                name: RouteNames.StationMessages,
                component: StationMessages,
                props: ({ params, query }) =>
                  ({
                    stationId: params.stationId,
                    forcedToMessagesRef: { value: Boolean(query.forcedToMessages) },
                    forwardPath: query.forwardPath,
                  }) as InstanceType<typeof IStationMessages>['$props'],
                beforeEnter: asyncRouteGuard(stationMessagesRouteGuard),
              },
              {
                path: 'sms',
                name: RouteNames.StationSms,
                component: LegacyViews,
                props: ({ params }) => ({ stationId: params.stationId, kind: RouteNames.StationSms }),
              },
              {
                path: 'info',
                name: RouteNames.StationInfo,
                component: LegacyViews,
                props: ({ params }) => ({ stationId: params.stationId, kind: RouteNames.StationInfo }),
              },
              {
                path: 'export',
                name: RouteNames.Export,
                component: RawDataExportView,
                props: ({ params }) => ({ stationId: params.stationId }),
              },
              {
                path: 'messenger-lists',
                name: RouteNames.MessengerListEditor,
                component: defineComponent({
                  inheritAttrs: false,
                  render() {
                    return h(ErrorBoundary, { stopPropagation: true }, () => [
                      h(DocumentTitle, {
                        modelValue: `${marketingAppTitle} - ${this.$t('App.PanelMessengerEditorLabel')}`,
                      }),
                      h(MessengerLists, this.$attrs),
                    ]);
                  },
                }),
                props: true,
              },
              {
                path: 'view/:viewId',
                name: RouteNames.View,
                component: defineComponent({
                  inheritAttrs: false,
                  beforeRouteEnter: asyncRouteGuard(fetchDataRouteGuard),
                  beforeRouteUpdate: asyncRouteGuard(fetchDataRouteGuard),
                  render() {
                    return h(ErrorBoundary, { stopPropagation: true }, () => [h(GenericView, this.$attrs)]);
                  },
                }),
                props: true,
              },
              {
                path: 'source/:source',
                name: RouteNames.ImageSource,
                component: defineComponent({
                  inheritAttrs: false,
                  render() {
                    const rootStore = useRootStore();
                    const appVersion = rootStore.appVersion;
                    const documentTitle = `${this.$attrs?.source || ''} - ${appVersion}`;

                    return h(ErrorBoundary, { stopPropagation: true }, () => [
                      h(DocumentTitle, { modelValue: documentTitle }),
                      h(ImageViewer, this.$attrs),
                    ]);
                  },
                }),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/error',
        name: RouteNames.Error,
        component: GenericError,
        meta: {
          isPublic: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: RouteNames.four04,
        component: Four04,
        meta: {
          isPublic: true,
        },
      },
    ],
  },
];

export default routes;
