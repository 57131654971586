import { defineStore } from 'pinia';

import { getViewInfo } from '@/api/station/station.api';
import { type ViewResponse } from '@/api/station/types';
import type { ViewsState, IFetchDataPayload } from './types';

interface AddViewInfo {
  id: string;
  payload: ViewResponse;
}

export const useViewStore = defineStore('view', {
  state: (): ViewsState => ({
    views: null,
  }),
  actions: {
    async fetchData({ viewId, stationId }: IFetchDataPayload): Promise<void> {
      const result = await getViewInfo(viewId, stationId);
      this.addViewInfo({ id: viewId, payload: result });
    },
    addViewInfo({ id, payload }: AddViewInfo): void {
      if (!this.views) {
        this.views = {};
      }

      this.views[id] = payload;
    },
  },
  getters: {
    getData: (state) => (viewId: string) => {
      return state.views?.[viewId];
    },
    hasViewInfo:
      (state) =>
      (viewId: string): boolean => {
        return !!state.views?.[viewId];
      },
  },
});
