
  import { defineComponent, onMounted, onUnmounted } from 'vue';
  import { debounce } from 'lodash-es';

  /**
   * This component addresses an issue with the `vh` unit on mobile devices.
   * On Android Chrome, Firefox and iOS Safari the Browser's app bar would not be taken into account when using
   * `100vh`.
   *
   * Here we intentionally avoid a potential fix with `min-height: -webkit-fill-available`. It causes the height of the body
   * to actually be less than the full screen-height in absence of a browser toolbar on Android Chrome. Most likely due to
   * a browser bug.
   */
  export default defineComponent({
    name: 'FullHeight',
    setup() {
      let debouncedSetHeight: any;
      const setViewHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };

      onMounted(() => {
        setViewHeight();

        debouncedSetHeight = debounce(setViewHeight, 50);

        window.addEventListener('resize', debouncedSetHeight);
      });

      onUnmounted(() => {
        window.removeEventListener('resize', debouncedSetHeight);
      });
    },
  });
