
  import { defineComponent } from 'vue';
  import { mdiCardAccountPhone, mdiChevronLeft, mdiMenu } from '@mdi/js';
  import { RouteNames } from '@/router/types';
  import { marketingAppTitle } from '@/common/constants';

  export default defineComponent({
    props: {
      drawerOpen: Boolean,
    },
    emits: ['toggle:drawer'],
    methods: {
      toggleDrawer() {
        this.$emit('toggle:drawer');
      },
    },
    computed: {
      mdiChevronLeftIcon() {
        return mdiChevronLeft;
      },
      mdiMenuIcon() {
        return mdiMenu;
      },
      mdiCardAccountPhoneIcon() {
        return mdiCardAccountPhone;
      },
      supportRouteName() {
        return RouteNames.Support;
      },
      marketingAppTitle() {
        return marketingAppTitle;
      },
    },
  });
