import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutoUpdate',
  props: {
    callback: {
      default: null,
      type: Function,
    },
    enabled: {
      default: false,
      type: Boolean,
    },
    interval: {
      default: 300,
      type: Number,
    },
  },
  watch: {
    enabled: {
      immediate: true,
      handler(newVal: boolean): void {
        if (newVal && !this.timer) {
          this.timer = requestAnimationFrame(this.update);
        } else {
          this.removeTimer();
        }
      },
    },
  },
  data() {
    return {
      timer: null as null | number,
      lastRunTime: null as null | number,
    };
  },
  beforeUnmount() {
    this.removeTimer();
  },
  methods: {
    removeTimer(): void {
      if (!this.timer) {
        return;
      }

      cancelAnimationFrame(this.timer);

      this.timer = null;
    },
    update(now: number) {
      if (!this.lastRunTime) {
        this.lastRunTime = now;
      }
      const timePassed = now - this.lastRunTime;

      if (timePassed >= this.interval * 1000) {
        this.callback(this);
        this.lastRunTime = now;
      }

      this.timer && cancelAnimationFrame(this.timer);

      if (this.enabled) {
        this.timer = requestAnimationFrame(this.update);
      }
    },
  },
  render() {
    return this.$slots.default ? this.$slots.default() : null;
  },
});
