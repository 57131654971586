
  import { defineComponent } from 'vue';
  import UserFriendlyError from '@/common/utils/UserFriendlyError';

  /**
   * @example [none]
   */
  export default defineComponent({
    name: 'UserFriendlyErrorMessage',
    props: {
      error: Error,
    },
    computed: {
      errorText(): string {
        return this.$t((this.error as UserFriendlyError).userFriendlyMessage) as string;
      },
    },
  });
