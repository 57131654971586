
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'DocumentTitle',
    props: {
      modelValue: String,
    },
    watch: {
      modelValue: {
        immediate: true,
        handler(newValue: string): void {
          if (newValue) {
            document.title = newValue;
          }
        },
      },
    },
  });
