import type { OidcModuleFactoryParams } from '@/pages/Oidc/store/types';
import { UserManagerSettings } from 'oidc-client-ts';

export const oidcSettingsFactory = ({
  authority,
  rootUrl,
  redirectPath,
  clientId,
  postLogoutRedirectUrl,
}: OidcModuleFactoryParams): UserManagerSettings => {
  const standaloneIdentifier = 'standalone=true';
  const isStandaloneMode = /standalone=true/.test(window.location.search);
  const isDevelopment = process.env.NODE_ENV === 'development';
  const finalRootUrl = isDevelopment ? `${window.location.protocol}//${window.location.host}` : rootUrl;
  const redirectUri = isStandaloneMode
    ? `${finalRootUrl}${redirectPath}?${standaloneIdentifier}`
    : `${finalRootUrl}${redirectPath}`;
  const finalPostLogoutRedirectUrl = isStandaloneMode ? postLogoutRedirectUrl : rootUrl;

  return {
    authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    post_logout_redirect_uri: finalPostLogoutRedirectUrl,
    response_type: 'code',
    response_mode: 'query',
    scope: 'openid profile',
    accessTokenExpiringNotificationTimeInSeconds: 30,
    automaticSilentRenew: true, // allows token renewal without redirect. Requires first-party cookies of the ID Provider!
    loadUserInfo: false,
    monitorSession: true, // allows the federated log-out. Requires first-party cookies of the ID Provider!
  };
};
