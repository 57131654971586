import { type Component, ComponentPublicInstance, defineComponent, h } from 'vue';
import { mapState } from 'pinia';

import { marketingAppTitle } from '@/common/constants';
import { SupportedViewKinds } from '@/api/station/types';
import Tile from '@/common/components/Tile/Tile.vue';
import DocumentTitle from '@/common/components/DocumentTitle/DocumentTitle.vue';
import { asyncComponentFactory } from '@/router/utils/async-component-factory';
import { getStationObserver } from '@/api/observations/observations.websocket';
import * as Sentry from '@sentry/browser';
import { WebsocketClient } from '@/api/utils/WebsocketClient';
import { useStationStore } from '@/areas/Station/store';
import { useViewStore } from '@/areas/GenericView/store';
import { useRootStore } from '@/store';

const ImageViewer = asyncComponentFactory(() => import('@/areas/ImageViewer/views/ImageViewer/ImageViewer.vue'));
const Chart = asyncComponentFactory(() => import('@/areas/Chart/views/Chart/Chart.vue'));
const Dashboard = asyncComponentFactory(() => import('@/areas/Dashboard'));
const LiveImage = asyncComponentFactory(() => import('@/areas/LiveImage/views/LiveImage/LiveImage.vue'));
const ThermistorChainViewer = asyncComponentFactory(
  () => import('@/areas/Chart/views/ThermistorChain/ThermistorChain.vue'),
);
const DataloggerControl = asyncComponentFactory(() => import('@/areas/DataloggerControl/views/DataloggerControl.vue'));
const ControlCenter = asyncComponentFactory(() => import('@/areas/ControlCenter/views/ControlCenter.vue'));
const ObservationMap = asyncComponentFactory(() => import('@/areas/ObservationMap/views/ObservationMap.vue'));
const MeasurementMap = asyncComponentFactory(() => import('@/areas/MeasurementMap/views/MeasurementMap.vue'));
const ExternalLink = asyncComponentFactory(() => import('@/areas/ExternalLink/views/ExternalLink.vue'));
const HtmlInfo = asyncComponentFactory(() => import('@/areas/HtmlInfo/views/HtmlInfo/HtmlInfo.vue'));
const MessengerLists = asyncComponentFactory(() => import('@/areas/MessengerLists/views/MessengerLists.vue'));
const RaumaDataProvider = asyncComponentFactory(() => import('@/areas/RaumaControlCenter/views/RaumaDataProvider.vue'));

// Holds the currently active WebsocketClient
const stationObserverCache = new WeakMap<ComponentPublicInstance, WebsocketClient>();

function appropriateComponent(type: Component, props: object | null, isWithoutTile: boolean, title: string) {
  if (isWithoutTile) {
    return [h(DocumentTitle, { modelValue: title }), h(type, props)];
  }

  return [h(Tile, () => [h(DocumentTitle, { modelValue: title }), h(type, props)])];
}

export default defineComponent({
  name: 'GenericView',
  methods: {
    update(): void {
      if (!this.$refs || !this.$refs.view) {
        throw 'No ref to update';
      }

      // @ts-ignore
      if (this.$refs.view.update === undefined) {
        throw 'No update() implemented on view';
      }

      // @ts-ignore
      this.$refs.view.update();
    },
  },
  props: {
    viewId: String,
    stationId: { type: String, required: true },
    withoutTile: Boolean,
  },
  watch: {
    stationId: {
      async handler() {
        if (stationObserverCache.has(this)) {
          await stationObserverCache.get(this)!.destroy();
        }
      },
    },
  },
  computed: {
    viewInfo() {
      return (this.viewId && this.getData(this.viewId)) || null;
    },
    stationObserver() {
      if (!this.viewInfo || !this.stationId) {
        return null;
      }

      // filter the view kinds to create a station observer for
      if ([SupportedViewKinds.ControlCenter, SupportedViewKinds.ObservationMap].includes(this.viewInfo.kind)) {
        const observer = getStationObserver({ stationId: this.stationId });
        stationObserverCache.set(this, observer);
        return observer;
      }

      return null;
    },
    ...mapState(useViewStore, ['getData']),
    ...mapState(useStationStore, ['getStationTimeZone']),
    ...mapState(useRootStore, ['appVersion', 'preferredTimezone']),
  },
  async beforeUnmount() {
    try {
      await stationObserverCache.get(this)?.destroy();
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  render: function () {
    if (this.viewId) {
      const viewInfo = this.viewInfo;
      const stationTimeZone = this.getStationTimeZone(this.stationId);
      const preferredTimezone = this.preferredTimezone;
      const isWithoutTile = this.$props.withoutTile;

      if (viewInfo) {
        const documentTitle = `${marketingAppTitle} - ${viewInfo.title}`;

        switch (viewInfo.kind) {
          case SupportedViewKinds.Chart: {
            return appropriateComponent(
              Chart,
              {
                options: viewInfo.data.highStockOptions,
                htmlInfo: viewInfo.data.htmlInfo,
                lastMeasurementAt: viewInfo.data.lastMeasurementAt,
                timeZone: stationTimeZone,
                preferredTimezone,
                title: viewInfo.title,
                viewId: this.viewId,
                stationId: this.stationId,
                ref: 'view',
              },
              isWithoutTile,
              documentTitle,
            );
          }
          case SupportedViewKinds.Dashboard: {
            return appropriateComponent(
              Dashboard,
              {
                id: viewInfo.id,
                initialDuration: viewInfo.data.initialDuration,
                showTimeSeriesLegends: viewInfo.data.showTimeSeriesLegends,
                linkedViews: viewInfo.data.linkedViews,
                stationId: this.stationId,
                timeZone: stationTimeZone,
              },
              true,
              documentTitle,
            );
          }
          case SupportedViewKinds.LiveImage: {
            return appropriateComponent(
              LiveImage,
              {
                id: viewInfo.id,
                url: viewInfo.data.liveUrl,
                title: viewInfo.title,
                ref: 'view',
              },
              isWithoutTile,
              documentTitle,
            );
          }
          case SupportedViewKinds.DataloggerControl: {
            return appropriateComponent(
              DataloggerControl,
              {
                id: viewInfo.id,
                proxyUrl: viewInfo.data.proxyUrl,
                autoTerminateAfter: viewInfo.data.autoTerminateAfter,
                title: viewInfo.title,
                ref: 'view',
              },
              isWithoutTile,
              documentTitle,
            );
          }
          case SupportedViewKinds.ImageViewer: {
            return appropriateComponent(
              ImageViewer,
              {
                id: viewInfo.id,
                initialMinQuality: viewInfo.data.initialMinQuality,
                liveUrl: viewInfo.data.liveUrl,
                allowQualityFiltering: viewInfo.data.allowQualityFiltering,
                source: viewInfo.data.source,
                htmlInfo: viewInfo.data.htmlInfo,
              },
              true /* avoid tile for ImageViewer */,
              documentTitle,
            );
          }
          case SupportedViewKinds.ControlCenter: {
            return appropriateComponent(
              ControlCenter,
              {
                id: viewInfo.id,
                title: viewInfo.title,
                stationId: this.stationId,
                stationObserver: this.stationObserver,
                timeZone: stationTimeZone,
                preferredTimeZone: preferredTimezone,
              },
              /* avoid tile for ControlCenter */ true,
              documentTitle,
            );
          }
          case SupportedViewKinds.ObservationMap: {
            return appropriateComponent(
              ObservationMap,
              {
                id: viewInfo.id,
                title: viewInfo.title,
                detectorIds: viewInfo.data.detectorIds,
                mapBackground: viewInfo.data.mapBackground,
                initialDuration: viewInfo.data.initialDuration,
                kmlFilePath: viewInfo.data.kmlFilePath,
                showTrajectoriesForAllUsers: viewInfo.data.showTrajectoriesForAllUsers,
                stationId: this.stationId,
                timeZone: stationTimeZone,
                stationObserver: this.stationObserver,
              },
              /* no tile for ObservationMap */ true,
              documentTitle,
            );
          }
          case SupportedViewKinds.ThermistorChain: {
            return appropriateComponent(
              ThermistorChainViewer,
              {
                viewId: viewInfo.id,
                stationId: this.stationId,
                timeZone: stationTimeZone,
                preferredTimezone,
                title: viewInfo.title,
                options: viewInfo.data.highStockOptions,
                htmlInfo: viewInfo.data.htmlInfo,
              },
              isWithoutTile,
              documentTitle,
            );
          }
          case SupportedViewKinds.MeasurementMap: {
            return appropriateComponent(
              MeasurementMap,
              {
                viewId: viewInfo.id,
                title: viewInfo.title,
                stationId: this.stationId,
                timeZone: stationTimeZone,
                preferredTimezone,
                initialDuration: viewInfo.data.initialDuration,
                mapBackground: viewInfo.data.mapBackground,
                groups: viewInfo.data.groups,
                labelRotation: viewInfo.data.labelRotation,
                decimalDigits: viewInfo.data.decimalDigits,
              },
              true,
              documentTitle,
            );
          }
          case SupportedViewKinds.ExternalLink: {
            return appropriateComponent(
              ExternalLink,
              {
                externalUrl: viewInfo.data.url,
              },
              true,
              documentTitle,
            );
          }
          case SupportedViewKinds.Html: {
            return appropriateComponent(
              HtmlInfo,
              {
                content: viewInfo.data.content,
              },
              isWithoutTile,
              documentTitle,
            );
          }
          case SupportedViewKinds.MessengerLists: {
            return appropriateComponent(
              MessengerLists,
              {
                stationId: this.stationId,
              },
              true,
              documentTitle,
            );
          }
          case SupportedViewKinds.RaumaControlCenter: {
            return appropriateComponent(
              RaumaDataProvider,
              {
                stationId: this.stationId,
                applianceId: viewInfo.data.applianceId,
                signalMappings: viewInfo.data.signalMappings,
                timeZone: stationTimeZone,
                preferredTimeZone: preferredTimezone,
              },
              true,
              documentTitle,
            );
          }
          default:
            return h('strong', 'Unsupported plot type!');
        }
      }
    }

    return '';
  },
});
