import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.returnUrl && _ctx.isValidReturnUrl)
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        icon: "",
        variant: "text",
        size: "x-small",
        onClick: _withModifiers(_ctx.goBack, ["stop"]),
        color: "primary",
        class: "returnToMobileApp",
        "data-testid": "returnToMobileApp"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mdiCloseIcon), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]))
    : _createCommentVNode("", true)
}