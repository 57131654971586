
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';

  import Spinner from '@/common/components/Spinner/Spinner.vue';
  import { useAuthStore } from '@/pages/Oidc/store';

  export default defineComponent({
    name: 'OidcLogout',
    components: {
      Spinner,
    },
    methods: {
      ...mapActions(useAuthStore, ['logout', 'login']),
    },
    computed: {
      ...mapState(useAuthStore, ['isAuthenticated']),
    },
    async created() {
      try {
        // when /logout is called directly for any reason (not from authenticated geoviz-ui session),
        // it will fail due to missing id_token_hint. The workaround is to try to authenticate the user,
        // after which he will be presented with a login screen.
        if (!this.isAuthenticated) {
          await this.login('/');
          return;
        }

        await this.logout();
      } catch (e) {
        // in case of a failure, redirect anyway
        window.location.replace(runtimeAppSettings.postLogoutRedirectUrl);
      }
    },
  });
