import { defineStore } from 'pinia';

import { type ISetErrorMutation, type IUser, type RootState, UserLevel } from '@/store/types';
import { CustomTheme } from '@/pages/Oidc/store/types';

export const useRootStore = defineStore('root', {
  state: (): RootState => ({
    error: null,
    errorIsIntrusive: false,
    errorKeepOpen: false,
    isInitialPageLoad: true,
    locale: null,
    userLevel: null,
    user: null,
    appVersion: process.env.VUE_APP_VERSION!,
    impersonator: null,
    customization: null,
    preferredTimezone: null,
  }),
  getters: {
    getCustomization: (state: RootState) => (): CustomTheme | null => {
      return state.customization === CustomTheme.Geopraevent ? null : state.customization;
    },
  },
  actions: {
    setError(payload: ISetErrorMutation) {
      if (!payload) {
        this.error = null;
        this.errorIsIntrusive = false;
        return;
      }

      const { error, intrusive, keepOpen } = payload;

      this.error = error;
      this.errorIsIntrusive = intrusive || false;
      this.errorKeepOpen = keepOpen || false;
    },
    setIsInitialPageLoad(isInitialPageLoad: boolean) {
      this.isInitialPageLoad = isInitialPageLoad;
    },
    setLocale(locale: string) {
      const allLowerCase = locale.toLowerCase();
      this.locale = allLowerCase;
    },
    setUserLevel(userLevel: UserLevel | null) {
      this.userLevel = userLevel;
    },
    setUser(user: IUser | null) {
      this.user = user;
    },
    setImpersonator(userName: string) {
      this.impersonator = userName;
    },
    setCustomization(customTheme: CustomTheme) {
      this.customization = customTheme;
    },
    setPreferredTimezone(preferredTimezone: string) {
      this.preferredTimezone = preferredTimezone;
    },
    updateLocale(locale: string) {
      this.setLocale(locale.toLowerCase());
    },
    updateUserLevel(userLevel: UserLevel | null) {
      this.setUserLevel(userLevel);
    },
    updateUser(user: IUser | null) {
      this.setUser(user);
    },
    updateImpersonater(userName: string) {
      this.setImpersonator(userName);
    },
    updateCustomization(customTheme: CustomTheme) {
      this.setCustomization(customTheme);
    },
    updatePreferredTimezone(preferredTimezone: string) {
      this.setPreferredTimezone(preferredTimezone);
    },
  },
});
