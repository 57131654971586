import { primary, secondary, background, listitem, listitemhover, darkSurface } from '../common/components/Color/Color';
import { VuetifyOptions } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

export const customTheme: VuetifyOptions = {
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    variations: {
      colors: ['primary', 'secondary', 'background'],
      lighten: 5,
      darken: 4,
    },
    themes: {
      light: {
        colors: {
          primary: primary.main,
          secondary: secondary.main,
          background,
          listitem,
          listitemhover,
        },
      },
      dark: {
        colors: {
          primary: primary.dark,
          secondary: secondary.dark,
          background,
          surface: darkSurface,
        },
      },
    },
  },
};
