import { IntlFormatters } from 'vue-intl';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $t: (key: string, values?: Record<string, any>) => ReturnType<IntlFormatters['formatMessage']>;
  }
}

export function intlKeyBridge(app: any) {
  app.config.globalProperties.$t = (key: string, values: any) =>
    app.config.globalProperties.$intl.formatMessage({ id: key }, values);

  // global dependency injection of the translation function for composition API style
  app.provide('$t', app.config.globalProperties.$t);
}
