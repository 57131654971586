import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTransition = _resolveComponent("CustomTransition")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_component_CustomTransition, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}