import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import * as Sentry from '@sentry/browser';

import ChunkLoadError from '@/common/utils/ChunkLoadError';
import type { RouteError } from '@/router/types';
import { useRootStore } from '@/store';
import { useAuthStore } from '@/pages/Oidc/store';
import routes from './routes';

declare module 'vue-router' {
  interface RouteMeta {
    isPublic?: boolean;
    isOidcCallback?: boolean;
  }
}

export function afterEach() {
  const rootStore = useRootStore();

  rootStore.setIsInitialPageLoad(false);
}

export function onError(error: RouteError) {
  const rootStore = useRootStore();
  const isInitialPageLoad = rootStore.isInitialPageLoad;
  let isStandaloneMode = false;

  if (error.to) {
    isStandaloneMode = Boolean(error.to.query.standalone);
  }

  // Handle errors when failing to resolve async route components
  if (error.name && error.name === 'ChunkLoadError') {
    rootStore.setError({ error: new ChunkLoadError(error), intrusive: false });
    return false;
  }

  if (!isInitialPageLoad) {
    rootStore.setError({ error, intrusive: false });
    Sentry.captureException(error);
    return false;
  }

  Sentry.withScope(function (scope) {
    scope.setLevel('fatal');
    Sentry.captureException(error);
  });

  rootStore.setError({ error, intrusive: true });

  router
    .replace({
      name: 'error',
      query: {
        standalone: isStandaloneMode ? String(isStandaloneMode) : undefined,
      },
    })
    .catch(
      // noop to prevent NavigationDuplicated error when navigating back after error
      // (programmatic navigation to identical route is considered an error)
      // see https://github.com/vuejs/vue-router/issues/2872#issuecomment-519073998
      () => {},
    );
}

const router = _createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// oidc middleware
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.isPublic || to.meta.isOidcCallback || authStore.isAuthenticated) {
    next();
  } else {
    authStore.login(to.fullPath).catch((e) => next(e));
  }
});

// util
router.afterEach(afterEach);

// error handler
router.onError(onError);

export default router;
