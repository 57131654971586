
  import { defineComponent } from 'vue';
  import { mdiInformationOutline } from '@mdi/js';
  import * as Sentry from '@sentry/browser';
  import { FormattedMessage } from '@/plugins/i18n/FormattedMessage';

  const paramName = 'redirect-source';

  export default defineComponent({
    name: 'DeprecatedRedirectNotification',
    components: { FormattedMessage },
    data() {
      return {
        isActive: false,
        redirectSource: '',
      };
    },
    computed: {
      mdiInfo() {
        return mdiInformationOutline;
      },
      domain() {
        return runtimeAppSettings.rootUrl;
      },
    },
    mounted() {
      this.checkRedirect();

      // watch for query parameter changes,
      // e.g. if oidc-callback redirects after login
      this.$watch(
        () => this.$route.query?.['redirect-source'],
        (newVal) => {
          !!newVal && this.checkRedirect();
        },
      );
    },
    methods: {
      checkRedirect() {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectSource = searchParams.get(paramName);

        if (redirectSource) {
          this.isActive = true;
          this.redirectSource = redirectSource;

          Sentry.captureMessage('Legacy redirect detected', 'info');
        }
      },
      onAcknowledge() {
        this.isActive = false;
        this.redirectSource = '';

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(paramName);

        const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(history.state, '', newRelativePathQuery);
      },
    },
  });
