import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "text-grey" }
const _hoisted_3 = { class: "d-flex justify-space-between align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_RawDataExportPlaceOrder = _resolveComponent("RawDataExportPlaceOrder")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_RawDataExportList = _resolveComponent("RawDataExportList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_sheet, { class: "px-4 pt-4" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('RawDataExport.Title')), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('RawDataExport.Subtitle')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_v_sheet, { class: "px-4 pt-4" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_v_btn, {
            "append-icon": _ctx.showPlaceForm ? _ctx.mdiChevronUp : _ctx.mdiChevronDown,
            color: "primary",
            variant: "outlined",
            onClick: _ctx.togglePlaceOrderForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('RawDataExport.Actions.PlaceExport')), 1)
            ]),
            _: 1
          }, 8, ["append-icon", "onClick"]),
          _createVNode(_component_v_tooltip, { location: "bottom" }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.fetchOrders(_ctx.stationId), ["stop"])),
                icon: "",
                variant: "text",
                class: "align-self-end mx-2",
                size: "small",
                color: "grey-darken-1",
                "data-testid": "raw-data-export-refresh-btn"
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.mdiRefreshIcon), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('RawDataExport.Actions.RefreshList')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_v_expand_transition, null, {
      default: _withCtx(() => [
        (_ctx.showPlaceForm)
          ? (_openBlock(), _createBlock(_component_v_sheet, {
              key: 0,
              class: "place-form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RawDataExportPlaceOrder, {
                  stationId: _ctx.stationId,
                  onClosed: _ctx.handleClose
                }, null, 8, ["stationId", "onClosed"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_sheet, { class: "d-flex flex-column pa-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_RawDataExportList, {
          stationId: _ctx.stationId,
          orders: _ctx.getOrders
        }, null, 8, ["stationId", "orders"])
      ]),
      _: 1
    })
  ], 64))
}