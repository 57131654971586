import { RouteLocationNormalized } from 'vue-router';
import { disableForceReadForImpersonatorStorageKey } from '@/common/constants';
import { useStationStore } from '@/areas/Station/store';
import { RouteNames } from '@/router/types';
import { UserLevel } from '@/store/types';
import { useRootStore } from '@/store';

export async function stationRouteGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
  const store = useStationStore();
  const rootStore = useRootStore();

  const stationId = to.params.stationId as string;
  const query = to.query;
  const isStandalone = query?.standalone === 'true';
  const userLevel = rootStore.userLevel;
  const isAdmin = userLevel === UserLevel.Admin;
  const isForceReadDisabled = Boolean(sessionStorage.getItem(disableForceReadForImpersonatorStorageKey)) ?? false;

  // update station info on every navigation,
  // except when only query params change,
  // so that new messages or views will be retrieved
  if (from.path !== to.path) {
    await store.fetchStationInfo(stationId);
  }

  // redirect to messages immediately if unread messages
  if (
    !isStandalone &&
    !isAdmin &&
    !isForceReadDisabled &&
    to.name !== RouteNames.StationMessages &&
    store.hasStationUnreadMessages(stationId)
  ) {
    next({
      name: RouteNames.StationMessages,
      params: {
        stationId,
      },
      query: {
        ...query,
        forcedToMessages: true,
        forwardPath: to.path,
      },
    });
    return;
  }

  if (to.name === RouteNames.Station && !to.params.viewId) {
    const viewId = store.getDefaultViewId(stationId);

    if (!viewId) {
      // redirect to messages by default
      next({
        name: RouteNames.StationMessages,
        params: {
          stationId,
        },
        query,
      });
      return;
    } else {
      next({
        name: RouteNames.View,
        params: {
          stationId,
          viewId,
        },
        query,
      });
      return;
    }
  }

  next();
}

export async function stationsRouteGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
  const stationId = to.params.stationId;
  const query = to.query;
  const isStandalone = query.standalone === 'true';
  const store = useStationStore();

  if (!store.hasStations && !isStandalone) {
    await Promise.all([store.fetchStations(), store.fetchFavourites()]);
  }

  if (!stationId) {
    const defaultStation = store.defaultStation;

    if (defaultStation) {
      next({
        name: RouteNames.Station,
        params: {
          stationId: defaultStation.id,
        },
        query: {
          ...query,
          forceShowStations: 'true',
        },
      });
      return;
    } else {
      next({
        name: RouteNames.four04,
      });
      return;
    }
  }

  next();
}

export async function loadStationsAndFavouritesRouteGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) {
  const store = useStationStore();
  if (!store.hasStations) {
    try {
      await Promise.all([store.fetchStations(), store.fetchFavourites()]);
    } catch (e) {
      // might fail due to user not logged in on /support route
    }
  }

  next();
}
