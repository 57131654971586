
  import { type Component, defineComponent } from 'vue';
  import * as Sentry from '@sentry/browser';

  import ErrorTemplate from '@/common/components/Error/ErrorTemplate.vue';

  export default defineComponent({
    name: 'error-boundary',
    components: {
      ErrorTemplate,
    },
    props: {
      stopPropagation: Boolean,
    },
    data() {
      return {
        err: null as null | Error,
        vm: null as null | Component,
        info: null as null | string,
        isDevelopment: process.env.NODE_ENV === 'development' ? true : false,
      };
    },
    errorCaptured(err, vm, info) {
      this.err = err as Error;
      this.vm = vm;
      this.info = info;

      Sentry.withScope(function (scope) {
        scope.setContext('vue', { info });
        Sentry.captureException(err);
      });

      if (this.isDevelopment) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      return !this.stopPropagation;
    },
  });
