
  import { defineComponent, PropType } from 'vue';
  import { mdiAccountCircle, mdiLogout, mdiMessageBulletedOff } from '@mdi/js';

  import { RouteNames } from '@/router/types';
  import { disableForceReadForImpersonatorStorageKey } from '@/common/constants';

  export default defineComponent({
    name: 'NavigationUserInfo',
    props: {
      user: Object,
      logout: Function,
      login: Function,
      impersonator: { type: String as PropType<string | null> },
    },
    data() {
      return {
        isForceReadDisabled: Boolean(sessionStorage.getItem(disableForceReadForImpersonatorStorageKey)) ?? false,
      };
    },
    computed: {
      mdiAccountCircleIcon() {
        return mdiAccountCircle;
      },
      mdiLogoutIcon() {
        return mdiLogout;
      },
      mdiDisableRedirectIcon() {
        return mdiMessageBulletedOff;
      },
      userProfileLink() {
        return {
          name: RouteNames.Profile,
        };
      },
    },
    methods: {
      disableForceRead() {
        sessionStorage.setItem(disableForceReadForImpersonatorStorageKey, '1');
        this.isForceReadDisabled = true;
      },
    },
  });
