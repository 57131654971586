import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fe65a8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partner-logo-container" }
const _hoisted_2 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imgSrc)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: _ctx.altText,
          src: _ctx.imgSrc,
          class: "partner-logo"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}