
  import { RawDataExportOrder, RawDataExportOrderProgress } from '@/api/station';
  import { mdiDownload } from '@mdi/js';
  import { defineComponent, PropType } from 'vue';
  import { formatToTimezone } from '@/common/utils/time';
  import { mapState } from 'pinia';
  import { useStationStore } from '@/areas/Station/store';
  import RawDataExportProgress from '../RawDataExportProgress/RawDataExportProgress.vue';

  export default defineComponent({
    name: 'RawDataExportListItem',
    components: { RawDataExportProgress },
    props: {
      stationId: { type: String, required: true },
      order: { type: Object as PropType<RawDataExportOrder>, required: true },
    },
    computed: {
      timeZone() {
        return this.getStationTimeZone(this.stationId);
      },
      timeStampTitle() {
        return this.$t('RawDataExport.ExportDateTimeAsText', {
          datetime: this.formatDate(this.order.timestamp, 'Pp'),
        });
      },
      mdiDownload() {
        return mdiDownload;
      },
      ...mapState(useStationStore, ['getStationTimeZone']),
    },
    methods: {
      formatDate(date: Date, format = 'P') {
        return formatToTimezone(date, this.timeZone, format);
      },
      isComplete(progress: RawDataExportOrderProgress) {
        return progress === RawDataExportOrderProgress.Completed;
      },
    },
  });
