
  import { defineComponent, PropType } from 'vue';
  import { mdiMagnify } from '@mdi/js';
  import { SimpleMeasurement } from '@/api/station';

  export default defineComponent({
    name: 'RawDataExportMeasurementChooser',
    emits: ['selectionChanged'],
    props: {
      stationId: { type: String, required: true },
      measurements: { type: Array as PropType<SimpleMeasurement[]>, required: true },
    },
    data() {
      return {
        selectedIds: [] as string[],
        allIds: this.measurements.map((m) => m.id),
        filterInput: '',
        filteredMeasurements: this.measurements,
      };
    },
    watch: {
      filterInput(newFilter: string) {
        this.filteredMeasurements = [
          ...this.measurements.filter((value) => value.name.toLowerCase().includes(newFilter.trim().toLowerCase())),
        ];
      },
      selectedIds(ids: string[]) {
        this.$emit('selectionChanged', ids);
      },
    },
    computed: {
      allSelected() {
        return this.selectedIds.length === this.allIds.length;
      },
      mdiMagnify() {
        return mdiMagnify;
      },
    },
    methods: {
      toggleSelectAll() {
        if (this.allSelected) {
          this.selectedIds = [];
        } else {
          this.selectedIds = [...this.allIds];
        }
      },
    },
  });
