
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'LabeledDivider',
    props: {
      text: String,
      thickness: {
        type: Number,
        default: 2,
      },
    },
  });
