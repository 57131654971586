import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createBlock(_component_v_list_item, {
        key: _ctx.name,
        to: _ctx.to,
        href: _ctx.href,
        target: _ctx.href ? '_blank' : undefined,
        density: "compact",
        class: "text-body-2",
        exact: ""
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_v_list_item_title, { class: "text-body-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.name), 1)
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (_ctx.icon)
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                (_ctx.hasUnreadMessages)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      dot: "",
                      floating: "",
                      color: "red",
                      "data-testid": "unreadMessagesIndicator"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.icon), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.icon), 1)
                      ]),
                      _: 1
                    }))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["to", "href", "target"]))
    : _createCommentVNode("", true)
}